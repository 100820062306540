import { QuestionLabel } from '@amo/core/components/containers';
import { PostalCodeCity, Text } from '@amo/core/components/forms';
import { normalizeTel } from '@amo/core/utils/normalize';
import { excludeCodePostal, required, validateEmail, validatePhone } from '@amo/core/utils/validateField';
import { Field } from 'redux-form';
import PersonneFields from '../../InformationsConducteur/PersonneFields/PersonneFields';

const FormRecontact = (props) => {
    const { changeValue, postalCodeCity, conducteurEstSouscripteur, titulaireCarteGrise, data } = props;

    return (
        <div>
            <div className={'bg-secondary-light p-4 rounded text-left mb-4'}>
                <p>Cher internaute,</p>
                <p>Les informations que vous avez saisies ne nous permettent pas de vous proposer un tarif en ligne.</p>

                <p>
                    L’un de nos conseillers peut vous contacter afin de vous proposer une solution d'assurance adaptée à
                    vos besoins
                </p>
            </div>

            <QuestionLabel>Être recontacté</QuestionLabel>

            {titulaireCarteGrise && titulaireCarteGrise === '2' ? (
                <>
                    <QuestionLabel className={'f-14 mt-4'} required>Raison sociale&nbsp;</QuestionLabel>
                    <div className={'row'}>
                        <div className={'col-12 col-md-4 mt-2 mt-md-2'}>
                            <Field
                                component={Text}
                                name={`DemandeTarif[ListePersonnes][1][Nom]`}
                                normalize={(v) => v.toUpperCase()}
                                validate={required}
                            />
                        </div>
                    </div>
                </>
            ) : conducteurEstSouscripteur === 'false' ? (
                <PersonneFields personneIndex={1} data={data}/>
            ) : (
                <PersonneFields personneIndex={0} data={data}/>
            )}

            <QuestionLabel className={'f-14 mt-4'} required>Adresse&nbsp;</QuestionLabel>
            <div className={'row'}>
                <div className={'col-12 col-md-4 mt-2 mt-md-2'}>
                    <Field
                        component={Text}
                        name={'DemandeTarif[ListePersonnes][0][Adresse1]'}
                        normalize={(v) => v.toUpperCase()}
                        validate={required}
                    />
                </div>
            </div>

            <QuestionLabel className={'f-14 mt-4'}>Complément d'adresse</QuestionLabel>
            <div className={'row'}>
                <div className={'col-12 col-md-4 mt-2 mt-md-2'}>
                    <Field
                        component={Text}
                        name={'DemandeTarif[ListePersonnes][0][Adresse2]'}
                        normalize={(v) => v.toUpperCase()}
                    />
                </div>
            </div>

            <QuestionLabel className={'f-14 mt-4'}>Complément d'adresse (2)</QuestionLabel>
            <div className={'row'}>
                <div className={'col-12 col-md-4 mt-2 mt-md-2'}>
                    <Field
                        component={Text}
                        name={'DemandeTarif[ListePersonnes][0][Adresse3]'}
                        normalize={(v) => v.toUpperCase()}
                    />
                </div>
            </div>

            <QuestionLabel className={'f-14 mt-4'} required>Ville / Code postal</QuestionLabel>
            <div className={'row'}>
                <div className={'col-12 col-md-4 mt-2 mt-md-2'} data-name="DemandeTarif[ListePersonnes][0][Ville]">
                    <PostalCodeCity
                        icon={'pin'}
                        id={'coordonnees'}
                        nameVille={'DemandeTarif[ListePersonnes][0][Ville]'}
                        changeValue={changeValue}
                        nameCodePostal={'DemandeTarif[ListePersonnes][0][CP]'}
                        validate={[excludeCodePostal]}
                        defaultValue={postalCodeCity}
                        required
                    />
                </div>
            </div>

            <QuestionLabel className={'f-14 mt-4'} required>N° de téléphone&nbsp;</QuestionLabel>
            <div className={'row'}>
                <div className={'col-12 col-md-4 mt-2 mt-md-2'}>
                    <Field
                        component={Text}
                        name={'DemandeTarif[ListePersonnes][0][TelPortable]'}
                        icon={'phone'}
                        iconSize={18}
                        validate={[required, validatePhone]}
                        normalize={normalizeTel}
                        onChange={(e) => changeValue('DemandeContrat[SignaturePhone]', normalizeTel(e.target.value))}
                    />
                </div>
            </div>
            <p className={'m-0 text-grey'}>
                <small>Il sera utilisé dans le cadre de la gestion de votre contrat</small>
            </p>

            <QuestionLabel className={'f-14 mt-4'} required>Votre adresse e-mail personnelle&nbsp;</QuestionLabel>
            <div className={'row'}>
                <div className={'col-12 col-md-4 mt-2 mt-md-2'}>
                    <Field
                        component={Text}
                        name={'DemandeTarif[ListePersonnes][0][Email]'}
                        icon={'envelope'}
                        validate={[required, validateEmail]}
                        iconSize={18}
                        onChange={(e) => changeValue('DemandeContrat[SignatureEmail]', e.target.value)}
                    />
                </div>
            </div>
            <p className={'m-0 text-grey'}>
                <small>Nous vous adresserons votre devis à cet email</small>
            </p>

            {conducteurEstSouscripteur === 'false' && (
                <>
                    <QuestionLabel>Le conducteur</QuestionLabel>
                    <PersonneFields personneIndex={0} data={data}/>
                </>
            )}
        </div>
    );
};

export default FormRecontact;
