import {useParams} from 'react-router-dom';
import InitContainer from "./Init/InitContainer";

const GetParams = (props) => {
    const { key } = useParams()

    return <InitContainer cle={key} />
};

export default GetParams;
