import {API_ERROR, API_SUCCESS, apiRequest} from '../../../actions/core/api/api.actions'
import {setError, setLoader} from '../../../actions/app/ui/ui.actions'
import {POST_NOT_FIND_VEHICULE} from "../../../actions/app/notFindVehicule/notFindVehicule.actions";
import {setNotification} from "../../../actions/core/notifications/notifications.actions";
import {reset} from "redux-form";

export const notFindVehiculeMiddleware = dispatch => next => action => {
    next(action)

    const { payload } = action

    switch (action.type){

        case POST_NOT_FIND_VEHICULE:
            let data = new FormData()

            Object.keys(payload.body).forEach(value => {
                if (payload.body[value] !== "" && payload.body[value] !== undefined) {
                    data.set(value, payload.body[value])
                }
            })

            next([
                apiRequest({body: data, method: 'POST', url: payload.data, entity: POST_NOT_FIND_VEHICULE}),
                setLoader({state: true, entity: POST_NOT_FIND_VEHICULE})
            ])

            break

        case `${POST_NOT_FIND_VEHICULE} ${API_SUCCESS}`:
            next([
                setError({state: false, entity: POST_NOT_FIND_VEHICULE}),
                setLoader({state: false, entity: POST_NOT_FIND_VEHICULE}),
                setNotification({entity: POST_NOT_FIND_VEHICULE, body: null, type: 'success', title: 'Merci, votre demande a bien été prise en compte.'}),
                reset('notFindVehicule')
            ])

            break

        case `${POST_NOT_FIND_VEHICULE} ${API_ERROR}`:
            next([
                setError({state: true, entity: POST_NOT_FIND_VEHICULE}),
                setLoader({state: false, entity: POST_NOT_FIND_VEHICULE})
            ])
            break

        default:
            return null
    }
}
