import * as Sentry from '@sentry/react';
import _ from 'lodash';
import InitFormulaireContainer from './InitFormulaire/InitFormulaireContainer';

const Init = (props) => {
    const { state } = props;
    const logs = [
        {
            libelle: 'form',
            state: state.form,
        },
        {
            libelle: 'step',
            state: state.ui.step,
        },
        {
            libelle: 'indexQuestion',
            state: state.ui.question,
        },
        {
            libelle: 'init',
            state: state.init,
        },
        {
            libelle: 'loading',
            state: state.ui.loading,
        },
        {
            libelle: 'error',
            state: state.ui.error,
        },
    ];

    _.map(logs, (log) => {
        Sentry.addBreadcrumb({
            category: log.libelle,
            message: JSON.stringify(log.state),
            level: 'debug',
        });
    });

    return <InitFormulaireContainer {...props} />;
};

export default Init;
