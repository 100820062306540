import { QuestionLabel } from '@amo/core/components/containers';
import { Text } from '@amo/core/components/forms';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Fade } from 'reactstrap';
import { Field } from 'redux-form';
import '../vehiculier.scss';
import { setFavoritesMarques } from './manageManuallyMarques.js';
import SelectCylindreeContainer from './SelectCylindree/SelectCylindreeContainer.js';

const SelectMarque = (props) => {
    const { type, changeValue, setVehiculierAffiche, marqueIdSelected, marques } = props;
    const [listMarques, setListMarques] = useState([]);
    const [listMarquesFilter, setListMarquesFilter] = useState([]);

    useEffect(() => {
        createDefaultArrayMarques();
    }, []);

    const createDefaultArrayMarques = () => {
        let listMarques = [];

        marques.map((marque) => {
            listMarques.push({
                id: marque.id,
                name: marque.name,
                favoris: setFavoritesMarques({ marque: marque.name, type }),
                logo: marque.img,
            });
            return marque;
        });

        setListMarques(listMarques);
        setListMarquesFilter(listMarques);
    };

    const filterArrayMarqueBySearch = (value) => {
        createDefaultArrayMarques();

        let filterBySearch = _.filter(listMarques, (m) => {
            return _.includes(m.name, _.upperCase(value));
        });

        setListMarquesFilter(filterBySearch);
    };

    const selectMarque = (marque) => {
        if (type === '50') {
            changeValue('cylindreeVehicule', 50);
        }

        changeValue('marqueIdVehicule', marque.id);
        changeValue('marqueVehicule', marque.name);
    };

    return (
        <Fade>
            {!marqueIdSelected ? (
                <div className={'row align-items-center'}>
                    <QuestionLabel className={'f-14 mt-0 mb-2 col-12 col-md-6'}>Selectionnez la marque :</QuestionLabel>

                    <div className={'col-12 col-md-6'}>
                        <Field
                            component={Text}
                            name={'find-marque'}
                            placeholder={'Recherchez votre marque'}
                            onChange={(e) => filterArrayMarqueBySearch(e.target.value)}
                        />
                    </div>

                    {_.orderBy(listMarquesFilter, ['favoris', 'name'], ['desc', 'asc']).map((marque, index) =>
                        marque.name ? (
                            <div
                                key={index}
                                onClick={() => selectMarque(marque)}
                                className={'col-6 col-md-4 mt-2 mt-md-2'}
                            >
                                <div className={'blocContainer border rounded text-center h-100 cursor-pointer p-3'}>
                                    {marque.logo ? (
                                        <div className={'containerLogo'}>
                                            <img src={marque.logo} alt="Logo marque" className={'d-inline-block'} />
                                        </div>
                                    ) : (
                                        <div className={'containerLogo'} />
                                    )}
                                    <span className={'mt-2'}>{marque.name}</span>
                                </div>
                            </div>
                        ) : (
                            ''
                        ),
                    )}
                </div>
            ) : (
                <SelectCylindreeContainer
                    marque_id={marqueIdSelected}
                    type={type}
                    setVehiculierAffiche={setVehiculierAffiche}
                />
            )}
        </Fade>
    );
};

SelectMarque.propTypes = {
    marqueIdSelected: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
    setVehiculierAffiche: PropTypes.func.isRequired,
};

export default SelectMarque;
