import { createStore, applyMiddleware } from 'redux'
import logger from 'redux-logger'
import AppReducer from './AppReducer'
import {coreMiddleware} from '../middlewares/core'
import {appMiddleware} from "../middlewares/app"

const middlewares = [...appMiddleware, ...coreMiddleware]

middlewares.push(logger)

export const store = createStore(
    AppReducer,
    applyMiddleware(...middlewares)
)
