import './footer.scss'

const Footer = () => {
    return (
        <div className={'footer'}>
            <div className={'container-fluid bg-dark p-4'}>
                <div className="row footer-link">
                    <div className={'col'}>
                        <p className={'f-14'}>En cas de problème sur le formulaire, copier-coller cette référence avant de nous l'envoyer :</p>
                    </div>
                    <p className={'f-14'}>Navigateur : {window.navigator.userAgent}</p>
                </div>
            </div>
        </div>
    );
};

export default Footer;
