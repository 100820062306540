import {connect} from 'react-redux'
import ProfilSideBar from "./ProfilSideBar";
import _ from 'lodash'
import {formValueSelector} from "redux-form";
import {getFormInputLabel} from "../../../../../../utils/transcoLabels";
import {getListCountries} from "../../../../../../redux/selectors/ui/ui.selectors";
import moment from "moment/moment";
import { listFormeJuridique } from '@amo/core/utils/valuesSelect.js';

const mapStateToProps = state => {
    const selector = formValueSelector('devis')
    const countries= getListCountries(state);
    const mineur = moment.isMoment(selector(state, 'DemandeTarif[ListePersonnes][0][DateNaissance]')) && moment(selector(state, 'DemandeTarif[ListePersonnes][0][DateNaissance]')).isValid() && moment(selector(state, 'DemandeTarif[ListePersonnes][0][DateNaissance]')).add(18, 'years') > moment()

    return {
        conducteurMineur: selector(state, 'typeVehicule') === '50' ? getFormInputLabel('DemandeTarif[ListePersonnes][ConducteurMineur]', selector(state, 'DemandeTarif[ListePersonnes][0][ConducteurMineur]')) : null,
        dateNaissance: selector(state, 'DemandeTarif[ListePersonnes][0][DateNaissance]'),
        birthCountry: _.find(countries, ['id', selector(state, 'DemandeTarif[ListePersonnes][0][BirthCountry]')])?.name,
        birthCity: selector(state, 'DemandeTarif[ListePersonnes][0][BirthCity]'),
        dateNaissanceSouscripteur: selector(state, 'DemandeTarif[ListePersonnes][1][DateNaissance]'),
        birthCountrySouscripteur: _.find(countries, ['id', selector(state, 'DemandeTarif[ListePersonnes][1][BirthCountry]')])?.name,
        birthCitySouscripteur: selector(state, 'DemandeTarif[ListePersonnes][1][BirthCity]'),
        typePermisAM: selector(state, 'DemandeTarif[ListePersonnes][0][ListePermis][0][TypePermis]'),
        datePermisAM: selector(state, 'DemandeTarif[ListePersonnes][0][ListePermis][0][DatePermis]'),
        typePermisA1: selector(state, 'DemandeTarif[ListePersonnes][0][ListePermis][1][TypePermis]'),
        datePermisA1: selector(state, 'DemandeTarif[ListePersonnes][0][ListePermis][1][DatePermis]'),
        typePermisA2: selector(state, 'DemandeTarif[ListePersonnes][0][ListePermis][2][TypePermis]'),
        datePermisA2: selector(state, 'DemandeTarif[ListePersonnes][0][ListePermis][2][DatePermis]'),
        typePermisA: selector(state, 'DemandeTarif[ListePersonnes][0][ListePermis][3][TypePermis]'),
        datePermisA: selector(state, 'DemandeTarif[ListePersonnes][0][ListePermis][3][DatePermis]'),
        typePermisB: selector(state, 'DemandeTarif[ListePersonnes][0][ListePermis][4][TypePermis]'),
        datePermisB: selector(state, 'DemandeTarif[ListePersonnes][0][ListePermis][4][DatePermis]'),
        valeurCrmMoto: selector(state, 'DemandeTarif[ListePersonnes][0][ValeurCrmMoto]'),
        legalStatus: _.find(listFormeJuridique, ['id', selector(state, 'DemandeTarif[ListePersonnes][1][LegalStatus]')])?.name,
        idSociety: selector(state, 'DemandeTarif[ListePersonnes][1][IdSociety]'),
        crmMoto50Plus3Ans: getFormInputLabel('DemandeTarif[ListePersonnes][0][CrmMoto50Plus3Ans]', selector(state, 'DemandeTarif[ListePersonnes][0][CrmMoto50Plus3Ans]')),
        valeurCrmAuto: selector(state, 'DemandeTarif[ListePersonnes][0][ValeurCrmAuto]'),
        crmAuto50Plus3Ans: getFormInputLabel('DemandeTarif[ListePersonnes][0][CrmAuto50Plus3Ans]', selector(state, 'DemandeTarif[ListePersonnes][0][CrmAuto50Plus3Ans]')),
        titulaireCarteGrise: mineur ? getFormInputLabel('titulaireCarteGriseMineur', selector(state, 'titulaireCarteGrise')) : getFormInputLabel('titulaireCarteGrise', selector(state, 'titulaireCarteGrise')),
        optinNewsletter: getFormInputLabel('OptinNewsletter', selector(state, 'OptinNewsletter')),
        optinUtilisationDonnees: getFormInputLabel('OptinUtilisationDonnees', selector(state, 'OptinUtilisationDonnees')),
    }
}

const ProfilSideBarContainer = connect(mapStateToProps)(ProfilSideBar)

export default ProfilSideBarContainer