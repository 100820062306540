import HeaderLandingMobile from "./HeaderLandingMobile/HeaderLandingMobile";
import HeaderLandingDesktop from "./HeaderLandingDesktop/HeaderLandingDesktop";
import './header_landing.scss'

const HeaderLanding = props => {

    return (
        <div className={'header_landing bg-grey-blue text-primary-dark position-relative'}>
            <div className={'d-lg-none py-4'}>
                <HeaderLandingMobile {...props}/>
            </div>
            <div className={'d-none d-lg-block'}>
                <HeaderLandingDesktop {...props}/>
            </div>
        </div>
    );
};

export default HeaderLanding;
