import PropTypes from 'prop-types'
import SideBarContent from "../SideBarContent/SideBarContent";
import moment from "moment";
import {getFormInputLabelSinistre} from "../../../../../../utils/transcoLabels";


const AntecedentsSideBar = (props) => {
    const {
        suspensionPermis, dateSuspensionPermis, nbMoisSuspensionPermis, annulationPermis, dateAnnulationPermis,
        condamnationConduiteSansAssurance,
        resiliationAssureur, motifResiliationAssureur, dateResiliationAssureur, sinistre_auto, listeSinistres,
        isCurrentStep, slug, goTo,
        assure_moto, nbMoisAssuranceMoto,
        assure_auto, nbMoisAssuranceAuto,
    } = props

    const correspondances = getFormInputLabelSinistre();
    let sinistres = '';

    if(listeSinistres) {
        listeSinistres.forEach(sinistre => {
            sinistres += (sinistres !== '') ? '<br/>' : '';
            sinistres += sinistre.NatureSinistre && sinistre.NatureSinistre !== 'Type de sinistre' ? `${correspondances['NatureSinistre'][sinistre.NatureSinistre]} ` : '';
            sinistres += moment.isMoment(sinistre.DateSinistre) && moment(sinistre.DateSinistre)._isValid ? `le ${sinistre.DateSinistre.format('L')} ` : '';
            sinistres += sinistre.VehiculeSinistre && sinistre.VehiculeSinistre !== 'Véhicule impliqué' ? `sur une ${correspondances['VehiculeSinistre'][sinistre.VehiculeSinistre]} ` : '';
            sinistres += sinistre.ResponsabiliteSinistre && sinistre.ResponsabiliteSinistre !== 'Taux de responsabilité' ? `<br/>Responsabilité de ${correspondances['ResponsabiliteSinistre'][sinistre.ResponsabiliteSinistre]} ` : '';
            sinistres += sinistre.TypeSinistre && sinistre.TypeSinistre !== 'Dommage(s) corporel(s)' ? `<br/>${correspondances['TypeSinistre'][sinistre.TypeSinistre]}` : '';

        });
    }

    return (
        <SideBarContent
            title={'Vos antécédents'}
            icon={'info'}
            isCurrentStep={isCurrentStep}
            slug={slug}
            goTo={goTo}
            tabContent={[
                {title: 'Votre permis a fait l\'objet d\'une suspension', 'value':  suspensionPermis },
                {title: 'Date de suspension de votre permis', 'value':  dateSuspensionPermis },
                {title: 'Nombre de mois de suspension de permis', 'value':  nbMoisSuspensionPermis },
                {title: 'Votre permis a fait l\'objet d\'une annulation', 'value':  annulationPermis },
                {title: 'Date d\'annulation de votre permis', 'value':  dateAnnulationPermis },
                {title: 'Annulation ou suspension du permis pour motif de conduite sans assurance', 'value':  condamnationConduiteSansAssurance },
                {title: 'Résiliation de contrat auprès d’un précédent assureur', 'value':  resiliationAssureur },
                {title: 'Motif de la résiliation', 'value':  motifResiliationAssureur },
                {title: 'Date de la résiliation', 'value':  dateResiliationAssureur },
                {title: 'Sinistres auto et/ou moto au cours de ces 3 dernières années', 'value':  sinistre_auto },
                {title: 'Vos sinistres', 'value':  sinistres },
                {title: 'Assurance 2-roues', 'value': assure_moto},
                {title: 'Nombre de mois assuré en 2-roues', 'value': nbMoisAssuranceMoto},
                {title: 'Assurance auto', 'value': assure_auto},
                {title: 'Nombre de mois assuré en auto', 'value': nbMoisAssuranceAuto},
            ]}
        />
    );
};

AntecedentsSideBar.propTypes = {
    slug: PropTypes.string.isRequired,
    goTo: PropTypes.func
};

export default AntecedentsSideBar;
