import PropTypes from 'prop-types';
import { useState } from 'react';
import NotFindVehiculeContainer from './NotFindVehicule/NotFindVehiculeContainer.js';
import SelectMarqueContainer from './SelectMarque/SelectMarqueContainer.js';
import { QuestionLabel } from '@amo/core/components/containers';
import { Radio } from '@amo/core/components/forms';

const Vehiculier = (props) => {
    const { setVehiculierAffiche, marque, cylindree, changeValue, is50 } = props;
    const [notFind, setNotFind] = useState(false);

    const resetSelectVehicule = () => {
        changeValue('cylindreeVehicule', '');
        changeValue('marqueIdVehicule', '');
        changeValue('marqueVehicule', '');
        changeValue('modeleVehicule', '');
        changeValue('code_categorie', '');
    };

    return (
        <div>
            {!notFind ? (
                <>
                    {marque && <QuestionLabel className={'f-14 mt-0 mb-2'}>Modifiez votre sélection :</QuestionLabel>}

                    {(marque || cylindree) && (
                        <>
                            <div className={'row'}>
                                {marque && (
                                    <div className={'col-6 col-md-4'}>
                                        <Radio input={{ onChange: () => resetSelectVehicule() }} label={marque} className={'small'}/>
                                    </div>
                                )}
                                {cylindree && !is50 && (
                                    <div className={'col-6 col-md-4'}>
                                        <Radio
                                            className={'w-auto small'}
                                            input={{
                                                onChange: () => {
                                                    changeValue('cylindreeVehicule', '');
                                                    changeValue('modeleVehicule', '');
                                                },
                                            }}
                                            label={String(cylindree)}
                                        />
                                    </div>
                                )}
                            </div>
                            <hr />
                        </>
                    )}

                    <SelectMarqueContainer setVehiculierAffiche={setVehiculierAffiche} />
                </>
            ) : (
                <NotFindVehiculeContainer retour={setNotFind} />
            )}
        </div>
    );
};

Vehiculier.defaultProps = {
    inputName: '',
};

Vehiculier.propTypes = {
    inputName: PropTypes.string,
};

export default Vehiculier;
