import motoverte from '@amo/core/assets/images/produits/icon_cross.svg';
import moto from '@amo/core/assets/images/produits/icon_moto.svg';
import quad from '@amo/core/assets/images/produits/icon_quad.svg';
import scooter from '@amo/core/assets/images/produits/icon_scooter.svg';
import { Modal, QuestionLabel, SimpleTitle } from '@amo/core/components/containers';
import { Radio, Text } from '@amo/core/components/forms';
import { required } from '@amo/core/utils/validateField';
import classNames from 'classnames';
import { useState } from 'react';
import { Field } from 'redux-form';
import QuestionContentContainer from '../../QuestionContent/QuestionContentContainer';
import SearchBarVehiculeContainer from '../../Vehiculier/SearchBarVehicule/SearchBarVehiculeContainer';
import VehiculierContainer from '../../Vehiculier/VehiculierContainer';
import BlocInformationsContainer from './BlocInformations/BlocInformationsContainer';

const Vehicule = (props) => {
    const {
        vehiculeIsDisabled,
        marque,
        modele,
        cylindree,
        familleProduit,
        changeValue,
        postDejaClient,
        loadingDejaClient,
        handleSubmit,
        nextStep,
        fillLoading,
        valid,
        dejaClientValide,
    } = props;
    const [vehiculierAffiche, setVehiculierAffiche] = useState(false);
    const [previousVehicule, setPreviousVehicule] = useState(familleProduit);

    const changeVehicule = (value) => {
        if (value !== previousVehicule) {
            setPreviousVehicule(value);
            changeValue('marqueIdVehicule', '');
            changeValue('marqueVehicule', '');
            changeValue('modeleVehicule', '');
            changeValue('code_categorie', '');
            changeValue('DemandeTarif[Vehicule][UsageVehicule]', null);

            if (value === '50') {
                changeValue('cylindreeVehicule', 50);
            } else {
                changeValue('cylindreeVehicule', '');
            }
        }
    };

    return (
        <>
            <div className={'mb-5'}>
                {
                    <div className="mt-3">
                        <QuestionLabel>Indiquez votre numéro de client ou de contrat&nbsp;:</QuestionLabel>
                        <div className="row">
                            <div className={'col-12 col-md-4 mt-2 mt-md-2'}>
                                <Field
                                    component={Text}
                                    name={`DemandeTarif[ListePersonnes][0][NumeroClient]`}
                                    placeholder={'N° client/contrat'}
                                />
                            </div>
                            <div className={'col-12 col-md-4 mt-2 mt-md-2'}>
                                <button
                                    className={`btn btn-primary medium-rounded mb-2 text-uppercase ${
                                        dejaClientValide && 'disabled'
                                    }`}
                                    onClick={() => postDejaClient()}
                                    disabled={dejaClientValide}
                                >
                                    {loadingDejaClient ? (
                                        <span
                                            className="spinner-border spinner-border-sm"
                                            role="status"
                                            aria-hidden="true"
                                        />
                                    ) : (
                                        "m'identifier"
                                    )}
                                </button>
                            </div>
                        </div>
                        <div className={'bg-warning p-4 rounded text-left mt-3 f-14'}>
                            <p className={'mb-0'}>
                                Le prospect est <strong>déjà client et souhaite modifier son contrat existant</strong>
                                (changement de véhicule à assurer, revue des garanties, revue de prix,…), vous devez le
                                rediriger vers ce numéro : 02 47 51 07 07 (Touche 1)
                            </p>
                        </div>
                    </div>
                }
            </div>
            <hr className={'mt-5 mb-5'} />
            <div>
                <SimpleTitle>Votre véhicule</SimpleTitle>
                <QuestionContentContainer name={'DemandeTarif[Vehicule][SituationVehicule]'}>
                    <QuestionLabel required>
                        Est-ce que vous possédez déjà votre deux-roues&nbsp;?
                    </QuestionLabel>
                    <div className={'row'}>
                        <div className={'col-12 col-md-4 mt-2 mt-md-2'}>
                            <Field
                                component={Radio}
                                name={'DemandeTarif[Vehicule][SituationVehicule]'}
                                value={'1'}
                                type={'radio'}
                                label={'Oui'}
                                onChange={() => changeValue('DemandeTarif[Vehicule][PossessionPlusDe3Mois]', null)}
                                validate={required}
                                className={'small'}
                            />
                        </div>
                        <div className={'col-12 col-md-4 mt-2 mt-md-2'}>
                            <Field
                                component={Radio}
                                name={'DemandeTarif[Vehicule][SituationVehicule]'}
                                value={'0'}
                                type={'radio'}
                                label={'Non'}
                                onChange={() => {
                                    changeValue('DemandeTarif[Vehicule][PossessionPlusDe3Mois]', '0');
                                    //Comme moins de 3 mois on par du principe qu'il a été assurés
                                    changeValue('DemandeTarif[Vehicule][Assure3DerniersMois]', '1');
                                }}
                                validate={required}
                                className={'small'}
                            />
                        </div>
                    </div>
                </QuestionContentContainer>

                <QuestionContentContainer name={'typeVehicule'}>
                    <QuestionLabel required>
                        Quel est le type de 2-roues que vous souhaitez assurer&nbsp;?&nbsp;
                    </QuestionLabel>
                    <div className={'row'}>
                        <div className={'col-6 col-md-4 mt-3 mt-md-2'}>
                            <Field
                                component={Radio}
                                name={'typeVehicule'}
                                value={'moto'}
                                type={'radio'}
                                label={'Moto'}
                                icon={moto}
                                validate={required}
                                onChange={(e) => changeVehicule(e.target.value)}
                                className={'text-center f-14'}
                            />
                        </div>
                        <div className={'col-6 col-md-4 mt-3 mt-md-2'}>
                            <Field
                                component={Radio}
                                name={'typeVehicule'}
                                value={'scooter'}
                                type={'radio'}
                                label={'Scooter'}
                                icon={scooter}
                                validate={required}
                                onChange={(e) => changeVehicule(e.target.value)}
                                className={'text-center f-14'}
                            />
                        </div>
                        <div className={'col-6 col-md-4 mt-3 mt-md-2'}>
                            <Field
                                component={Radio}
                                name={'typeVehicule'}
                                value={'50'}
                                type={'radio'}
                                label={'Cyclo 50 cm3'}
                                icon={scooter}
                                validate={required}
                                onChange={(e) => changeVehicule(e.target.value)}
                                className={'text-center f-14'}
                            />
                        </div>
                        <div className={'col-6 col-md-4 mt-3 mt-md-2'}>
                            <Field
                                component={Radio}
                                name={'typeVehicule'}
                                value={'quad'}
                                type={'radio'}
                                label={'Quad'}
                                icon={quad}
                                validate={required}
                                onChange={(e) => changeVehicule(e.target.value)}
                                className={'text-center f-14'}
                            />
                        </div>
                        <div className={'col-6 col-md-4 mt-3 mt-md-2'}>
                            <Field
                                component={Radio}
                                name={'typeVehicule'}
                                value={'verte'}
                                type={'radio'}
                                label={'Moto verte'}
                                icon={motoverte}
                                validate={required}
                                onChange={(e) => changeVehicule(e.target.value)}
                                className={'text-center f-14'}
                            />
                        </div>
                    </div>
                </QuestionContentContainer>

                <QuestionContentContainer name={'modeleVehicule'}>
                    <QuestionLabel required>
                        Quel est le modèle de votre 2-roues&nbsp;?&nbsp;
                    </QuestionLabel>
                    <div className="row" style={{ alignItems: 'flex-start' }}>
                        {modele && (
                            <div className={'mt-3 font-weight-bold text-primary-light f-14 col-12'}>
                                <span className={'text-initial'}>Votre véhicule :</span>{' '}
                                <span dangerouslySetInnerHTML={{ __html: `${marque} ${cylindree} ${modele}` }} />
                            </div>
                        )}
                        {familleProduit && <SearchBarVehiculeContainer />}

                        <div className={'col-12 col-md-5 mt-2 mt-md-2'}>
                            <Radio
                                label={!modele ? 'Sélectionnez votre modèle' : 'Modifiez votre modèle'}
                                type={'checkbox'}
                                input={{
                                    onChange: () => setVehiculierAffiche(!vehiculierAffiche),
                                    checked: !!marque,
                                }}
                                className={'small'}
                            />
                        </div>
                    </div>
                </QuestionContentContainer>

                {vehiculierAffiche && (
                    <Modal
                        bodyScrollable
                        onClick={() => {
                            setVehiculierAffiche(!vehiculierAffiche);
                        }}
                        large
                        title={'Sélectionnez votre véhicule'}
                    >
                        <VehiculierContainer
                            inputName={'DemandeTarif[Vehicule]'}
                            setVehiculierAffiche={setVehiculierAffiche}
                        />
                    </Modal>
                )}

                {vehiculeIsDisabled && (
                    <div className={'bg-danger p-4 rounded text-left mt-2 f-14'}>
                        <p
                            className={'m-0'}
                            dangerouslySetInnerHTML={{
                                __html: `Attention, nous n'assurons pas ce type de véhicule. (${marque} ${cylindree} ${modele})`,
                            }}
                        />
                    </div>
                )}

                { !vehiculeIsDisabled && <BlocInformationsContainer cylindree={cylindree} /> }
            </div>

            <div className={'submit-row mt-5 text-right'}>
                <button
                    className={classNames(
                        (!valid || vehiculeIsDisabled) && 'disabled',
                        'btn btn-primary medium-rounded mb-2 order-lg-1',
                    )}
                    onClick={valid && !vehiculeIsDisabled ? handleSubmit(nextStep) : null}
                    data-id="btn-next"
                >
                    {fillLoading ? (
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
                    ) : (
                        'Continuer'
                    )}
                </button>
            </div>
            <div className="row">
                <p className={'mt-4 text-grey'}>
                    <small>Merci de vérifier si tous les champs du formulaire ont bien été saisis.</small>
                </p>
            </div>
        </>
    );
};

export default Vehicule;
