import {Icons} from "@amo/core/components/containers";

const ErreurTarif = (props) => {
    const { text, previousStep } = props

    return (
        <div>
            <div className={'bg-secondary-light p-4 mb-5 rounded text-center'}>
                <div className={'f-21 text-danger mb-3'}>
                    <Icons className={`d-inline-block position-relative mr-2`} fontSize={30} icon={'triangle'}/>
                    <p>Cher internaute,</p>
                    <p>Les informations que vous avez saisies ne nous permettent pas de vous proposer un tarif en
                        ligne.</p>
                    <p>Le tarif est en feu rouge.</p>
                    <br/>
                    <b>Ces informations ne doivent pas être communiquées au client</b>
                    <p>Motif du refus INTERNE : {text}</p>
                    <p>A très bientôt</p>
                </div>
                <button className={`btn btn-outline-primary medium-rounded order-lg-0`} onClick={() => previousStep()}>
                    Retour
                </button>
            </div>
        </div>
    );
};

export default ErreurTarif;
