import {connect} from 'react-redux'
import Paiement from "./Paiement";
import {
    getImmatriculationVehiculeDemandeTarifInit,
    getLibelleFormuleDevisInit,
    getToken, getIsSendMailPaiement
} from "../../../../../../redux/selectors/init/init.selectors";
import {getMarqueVehicule, getModeleVehicule} from "../../../../../../redux/selectors/vehicule/vehicule.selectors";
import { API_URL_WS_FORMULAIRE, APP_URL_APPLICATION } from '@amo/core/constants/constants.js';
import {getFormValues} from "redux-form";
import {postPay} from "../../../../../../redux/actions/app/pay/pay.action";

const mapStateToProps = state => {
    return {
        formule: getLibelleFormuleDevisInit(state),
        marque: getMarqueVehicule(state),
        modele: getModeleVehicule(state),
        immatriculation: getImmatriculationVehiculeDemandeTarifInit(state),
        url_pay: {
            uri: `${API_URL_WS_FORMULAIRE}/pay/${getToken(state)}`,
            token: getToken(state),
            url_return: `${APP_URL_APPLICATION}/paiement`
        },
        sendMailPaiement: getIsSendMailPaiement(state),
        form: getFormValues('devis')(state),
    }
}

const mapDispatchToProps = {
    postPay
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { url_pay, form } = stateProps
    const { postPay } = dispatchProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        postPay: () => postPay({query: url_pay.uri, form, url_pay}),
    }
}

const PaiementContainer = connect(mapStateToProps, mapDispatchToProps, mergeProps)(Paiement)

export default PaiementContainer
