import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import SelectCylindree from "./SelectCylindree.jsx";
import {change, formValueSelector} from "redux-form";
import {loader, SmallSpinner} from "@amo/core/components/loaders";
import {
    FETCH_CYLINDREES_VEHICULIER,
    fetchCylindreeVehicule
} from "../../../../../../../redux/actions/app/vehiculier/vehiculier.actions.js";
import {
    getCylindreesVehiculeUi,
    getErrorEntity,
    getLoadingEntity
} from "../../../../../../../redux/selectors/ui/ui.selectors.js";

const mapStateToProps = state => {
    const selector = formValueSelector('devis')

    return {
        cylindreeSelected: selector(state, `cylindreeVehicule`),
        cylindrees: getCylindreesVehiculeUi(state),
        loaded: getLoadingEntity(state, FETCH_CYLINDREES_VEHICULIER) === false,
        error: getErrorEntity(state, FETCH_CYLINDREES_VEHICULIER),
        familleProduit: selector(state, 'typeVehicule'),
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    changeValue: (field, value) => change('devis', field, value),
    fetchCylindreeVehicule: ({type, marque_id}) => fetchCylindreeVehicule({type, marque_id})
}, dispatch)

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { type, marque_id } = ownProps
    const { fetchCylindreeVehicule } = dispatchProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        load: () => fetchCylindreeVehicule({type, marque_id})
    }
}

const SelectCylindreeContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    loader({LoadingIndicator: SmallSpinner})
)(SelectCylindree)


export default SelectCylindreeContainer
