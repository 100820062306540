import {Icons} from "@amo/core/components/containers";
import './signature.scss'

const Signature = () => {
    return (
        <div className={'etapeSignature'}>

            <div className={'bg-secondary-light p-4 mb-5 blocSuccess text-center rounded'}>
                <div className={'f-21 textCheck text-primary-light mb-5'}>
                    <Icons className={`p-2 border full-rounded border-primary-light d-inline-block position-relative align-middle mr-2`} fontSize={7} icon={'Shape'}/>
                    Signature électronique
                </div>
                <div className={'f-18 mb-3'}>
                    Un email vient d'être envoyé au client pour la signature électronique.
                </div>
            </div>
        </div>
    );
};


export default Signature;
