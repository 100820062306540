import _ from 'lodash';
import { Field } from 'redux-form';
import {Icons, TableCollapse} from "@amo/core/components/containers";
import TablePrice from './TablePrice/TablePrice.jsx';
import {RadioBtn} from "@amo/core/components/forms";

const TableFormule = (props) => {
    const { listeFormules, formulePreconisee, fractionnement, formuleSelectionnee } = props;

    return (
        <div className="table-responsive">
            <table className={'table tableCard'}>
                <thead>
                    <tr>
                        <th className={'border-0 p-0'}>
                            <div className={'d-inline-block w-100 bg-secondary-light text-initial'}>Vos garanties</div>
                        </th>
                        {listeFormules &&
                            _.map(listeFormules, (f) => (
                                <th
                                    className={`${
                                        f.CodeFormule === formuleSelectionnee
                                            ? 'bg-primary text-white f-18'
                                            : 'bg-grey-medium f-18'
                                    } border-bottom-0 position-relative`}
                                    key={f.CodeFormule}
                                >
                                    <div>
                                        <Field
                                            name="DemandeTarif[Police][FormuleChoisie]"
                                            component={RadioBtn}
                                            value={f.CodeFormule}
                                            type={'radio'}
                                            label={''}
                                            className={'pt-5 px-2 pb-4'}
                                        >
                                            <p className={'mb-0'}>
                                                {f.CodeFormule === formulePreconisee && (
                                                    <span className="badge badge-dark position-absolute reco">Recommandée</span>
                                                )}
                                                {f.LibelleFormule}
                                                <br />
                                                {fractionnement === 'M' ? (
                                                    <TablePrice
                                                        frequence={'mois'}
                                                        prix={f.PrimeMensuelleBase}
                                                        prix_promo={f.PrimeMensuelleBasePromo}
                                                    />
                                                ) : (
                                                    <TablePrice
                                                        frequence={'an'}
                                                        prix={f.PrimeAnnuelleFracAnnuelBase}
                                                        prix_promo={f.PrimeAnnuelleFracAnnuelBasePromo}
                                                    />
                                                )}
                                            </p>
                                        </Field>
                                    </div>
                                </th>
                            ))}
                    </tr>
                </thead>
                <tbody className={'text-center'}>
                    {listeFormules &&
                        _.map(
                            _.filter(_.last(listeFormules).ListeGaranties, (o) => o.Disponible === 'Inclusion'),
                            (g, i) => (
                                <tr key={i}>
                                    <th scope="row" className={`f-14 font-weight-normal text-initial text-left`}>
                                        <TableCollapse title={<div>{g.LibelleGarantie}</div>}>
                                            {g.texteDeroulant && (
                                                <div dangerouslySetInnerHTML={{ __html: g.texteDeroulant }} />
                                            )}
                                        </TableCollapse>
                                    </th>
                                    {listeFormules &&
                                        _.map(listeFormules, (f, j) => (
                                            <td
                                                className={`${
                                                    f.CodeFormule === formuleSelectionnee && 'bg-primary-light '
                                                } align-middle`}
                                                key={j}
                                            >
                                                {_.find(
                                                    f.ListeGaranties,
                                                    (h) =>
                                                        h.CodeGarantie === g.CodeGarantie &&
                                                        h.Disponible === 'Inclusion',
                                                ) && (
                                                    <Icons
                                                        className={`text-primary border full-rounded ${
                                                            f.CodeFormule === formuleSelectionnee
                                                                ? 'bg-white text-white border-white'
                                                                : 'border-primary-light'
                                                        }`}
                                                        fontSize={5}
                                                        icon={'Shape'}
                                                    />
                                                )}
                                            </td>
                                        ))}
                                </tr>
                            ),
                        )}
                </tbody>
            </table>
        </div>
    );
};

export default TableFormule;
