import {connect} from 'react-redux'
import EnvoiPieces from "./EnvoiPieces";
import {getDocumentsUrlInit, getListeDocumentsContratInit} from "../../../../../../redux/selectors/init/init.selectors";

const mapStateToProps = state => {
    return {
        documentsUrl: getDocumentsUrlInit(state),
        listeDocuments: getListeDocumentsContratInit(state)
    }
}

const EnvoiPiecesContainer = connect(mapStateToProps)(EnvoiPieces)

export default EnvoiPiecesContainer
