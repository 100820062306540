import {connect} from 'react-redux'
import PopupDevis from "./PopupDevis";
import {getUrlPDFDevisInit, getUrlPDFFicheConseilInit, getEmailSouscripteurTarif} from "../../../../../../redux/selectors/init/init.selectors";
import {
    getUrlPDFConventionAssistanceTarif,
    getUrlPDFDispositionsGeneralesTarif, getUrlPDFDocumentInformationTarif
} from "../../../../../../redux/selectors/tarif/tarif.selectors";

const mapStateToProps = state => {
    return {
        email: getEmailSouscripteurTarif(state),
        pdfDevis: getUrlPDFDevisInit(state),
        conventionAssistance : getUrlPDFConventionAssistanceTarif(state),
        dispositionGenerale: getUrlPDFDispositionsGeneralesTarif(state),
        documentInformation: getUrlPDFDocumentInformationTarif(state),
        ficheConseil: getUrlPDFFicheConseilInit(state)
    }
}

const PopupDevisContainer = connect(mapStateToProps)(PopupDevis)

export default PopupDevisContainer
