import { QuestionLabel } from '@amo/core/components/containers';
import { required, validateEmail } from '@amo/core/utils/validateField.js';
import { Field } from 'redux-form';
import { Text } from "@amo/core/components/forms";
import { SmallSpinner } from '@amo/core/components/loaders';

const NotFindVehicule = (props) => {
    const { retour, changeValue, handleSubmit, valid, loading } = props;

    return (
        <form onSubmit={handleSubmit}>
            <p className={'f-14 m-0'}>Vous ne trouvez pas votre véhicule ?</p>
            <p className={'f-14'}>Complétez vos informations nous vous recontacterons le plus vite possible !</p>

            <QuestionLabel className={'f-14 mt-0'}>les informations de votre véhicule&nbsp;:</QuestionLabel>
            <div className="row">
                <div className={'col-6 mt-2 mt-md-2'}>
                    <Field component={Text} name={'marque'} label={'La marque'} />
                </div>
                <div className={'col-6 mt-2 mt-md-2'}>
                    <Field component={Text} name={'cylindree'} label={'La cylindrée'} />
                </div>
                <div className={'col-6 mt-2 mt-md-2'}>
                    <Field component={Text} name={'modele'} label={'Le modèle'} />
                </div>
                <div className={'col-12 mt-3'}>
                    <QuestionLabel className={'f-14 mt-0 mb-3'}>Votre carte grise&nbsp;:</QuestionLabel>
                    <input
                        type="file"
                        id="file"
                        name={'file'}
                        onChange={(e) => changeValue('file', e.target.files[0])}
                        accept="image/*,.pdf"
                    />
                </div>
            </div>

            <QuestionLabel className={'f-14 mt-5'} required>
                Vos informations&nbsp;:
            </QuestionLabel>
            <div className="row">
                <div className={'col-6 mt-2 mt-md-2'}>
                    <Field component={Text} name={'nom'} label={'Votre nom'} validate={required} />
                </div>
                <div className={'col-6 mt-2 mt-md-2'}>
                    <Field component={Text} name={'prenom'} label={'Votre prénom'} validate={required} />
                </div>
                <div className={'col-6 mt-2 mt-md-2'}>
                    <Field component={Text} name={'email'} label={'Votre email'} validate={[required, validateEmail]} />
                </div>
            </div>
            <div className="row justify-content-around">
                {loading ? (
                    <SmallSpinner className={'mt-5'} />
                ) : (
                    <>
                        <span className="btn btn-outline-primary medium-rounded my-4" onClick={() => retour(false)}>
                            Retour
                        </span>
                        <button
                            type={'submit'}
                            className={`${!valid && 'disabled'} btn btn-primary medium-rounded my-4 w-50`}
                        >
                            Envoyer
                        </button>
                    </>
                )}
            </div>
        </form>
    );
};

export default NotFindVehicule;
