import PropTypes from 'prop-types'
import SideBarContent from "../SideBarContent/SideBarContent";
import moment from "moment";

const ProfilSideBar = (props) => {
    const {
        conducteurMineur, dateNaissance, dateNaissanceSouscripteur,
        typePermisAM, datePermisAM, typePermisA1, datePermisA1, typePermisA2, datePermisA2, typePermisA, datePermisA, typePermisB, datePermisB,
        valeurCrmMoto, crmMoto50Plus3Ans, valeurCrmAuto, crmAuto50Plus3Ans,optinNewsletter, optinUtilisationDonnees, titulaireCarteGrise,
        isCurrentStep, slug, goTo, birthCountry,birthCity, birthCountrySouscripteur, birthCitySouscripteur,
        legalStatus, idSociety
    } = props

    let permisPossedes = '';
    let variableTabContent = [];

    const updatePermisPossedes = (typePermis, datePermis) => {
        if(typePermis){
            permisPossedes += (permisPossedes!=='') ? '<br/>' : '';
            permisPossedes += moment.isMoment(datePermis) && moment(datePermis)._isValid ? `${typePermis} depuis le ${datePermis.format('L')}` : typePermis;
        }
    }

    updatePermisPossedes(typePermisAM, datePermisAM);
    updatePermisPossedes(typePermisA1, datePermisA1);
    updatePermisPossedes(typePermisA2, datePermisA2);
    updatePermisPossedes(typePermisA, datePermisA);
    updatePermisPossedes(typePermisB, datePermisB);

    return (
        <SideBarContent
            title={'Votre profil'}
            icon={'bicker'}
            isCurrentStep={isCurrentStep}
            slug={slug}
            goTo={goTo}
            tabContent={[
                ...variableTabContent,
                {title: 'Le futur conducteur ne dispose pas encore du permis AM ou n\'a pas 14 ans', 'value':  conducteurMineur },
                {title: 'Date de naissance du conducteur', 'value':  dateNaissance },
                {title: 'Pays de naissance du conducteur', 'value':  birthCountry },
                {title: 'Ville de naissance du conducteur', 'value':  birthCity },
                {title: 'Date de naissance du représentant légal', 'value':  dateNaissanceSouscripteur },
                {title: 'Pays de naissance du représentant légal', 'value':  birthCountrySouscripteur },
                {title: 'Ville de naissance du représentant légal', 'value':  birthCitySouscripteur },
                {title: 'La carte grise est', 'value': titulaireCarteGrise},
                {title: 'Statut juridique de l\`entreprise', 'value': legalStatus},
                {title: 'Numéro de SIREN', 'value': idSociety},

                {title: 'Permis possédés', 'value': permisPossedes},
                {title: 'Bonus 2-roues actuel', 'value': valeurCrmMoto ? Number(valeurCrmMoto).toFixed(2) : valeurCrmMoto},
                {title: 'Vous avez ce bonus 2-roues depuis plus de 3 ans', 'value': crmMoto50Plus3Ans},
                {title: 'Bonus auto actuel', 'value': valeurCrmAuto ? Number(valeurCrmAuto).toFixed(2) : valeurCrmAuto},
                {title: 'Vous avez ce bonus auto depuis plus de 3 ans', 'value': crmAuto50Plus3Ans},
                {title: 'Utilisation des données personnelles pour de nouvelles offres et des actualités APRIL Moto', 'value': optinNewsletter},
                {title: 'Utilisation des données personnelles pour la publicité d\'offre d\'assurances ou autres produits analogues par les partenaires d\'APRIL Moto', 'value': optinUtilisationDonnees},
            ]}
        />
    );
};

ProfilSideBar.propTypes = {
    slug: PropTypes.string.isRequired,
    goTo: PropTypes.func
};

export default ProfilSideBar;
