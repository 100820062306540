import { Radio } from '@amo/core/components/forms';
import { errorOnQuad, onError, required } from '@amo/core/utils/validateField';
import _ from 'lodash';
import { Field } from 'redux-form';

const TitulaireCarteGriseMajeur = (props) => {
    const { changeValue, conducteur, familleProduit } = props;

    return (
        <>
            <div className={'col-12 col-md-4 mt-2 mt-md-2'}>
                <Field
                    component={Radio}
                    name={'titulaireCarteGrise'}
                    value={'1'}
                    type={'radio'}
                    label={'À votre nom'}
                    validate={[required, onError]}
                    onChange={() => {
                        changeValue('DemandeTarif[ListePersonnes]', [{ ...conducteur }]);
                        changeValue('DemandeTarif[ListePersonnes][0][Souscripteur]', true);
                    }}
                    className={'small'}
                />
            </div>

            {!_.includes(['quad', 'verte'], familleProduit) && (
                <div className={'col-12 col-md-4 mt-2 mt-md-2'}>
                    <Field
                        component={Radio}
                        name={'titulaireCarteGrise'}
                        value={'2'}
                        type={'radio'}
                        label={"Au nom d'une société"}
                        validate={[required, onError, errorOnQuad]}
                        onChange={() => {
                            changeValue('DemandeTarif[ListePersonnes][1][TypePersonne]', 'M');
                            changeValue('DemandeTarif[ListePersonnes][1][Civilite]', '3');
                            changeValue('DemandeTarif[ListePersonnes][1][Souscripteur]', true);
                            changeValue('DemandeTarif[ListePersonnes][0][Souscripteur]', false);
                        }}
                        className={'small'}
                    />
                </div>
            )}

            <div className={'col-12 col-md-4 mt-2 mt-md-2'}>
                <Field
                    component={Radio}
                    name={'titulaireCarteGrise'}
                    value={'onError'}
                    type={'radio'}
                    label={'Autre'}
                    validate={[required, onError]}
                    className={'small'}
                />
            </div>
        </>
    );
};

export default TitulaireCarteGriseMajeur;
