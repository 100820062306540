export const DEJA_CLIENT = '[Deja Client]'

export const POST_DEJA_CLIENT = `${DEJA_CLIENT} Post`

export const postDejaClient = ({idDejaClient, query}) => ({
    type: POST_DEJA_CLIENT,
    payload: {
        data: query,
        body: {idDejaClient}
    }
})
