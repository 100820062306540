import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import Recontact from "./Recontact";
import {change, formValueSelector, getFormValues} from "redux-form";
import _ from "lodash";
import {
    getPersonnePrincipaleIsSouscripteurDemandeTarifInit,
    getState
} from "../../../../../../redux/selectors/init/init.selectors";

const mapStateToProps = state => {
    const selector = formValueSelector('devis')
    const ville = selector(state, 'DemandeTarif[ListePersonnes][0][Ville]')
    const cp = selector(state, 'DemandeTarif[ListePersonnes][0][CP]')
    const ListePersonnes = selector(state, 'DemandeTarif[ListePersonnes]')
    const conducteurPrincipal = ListePersonnes ? _.find(ListePersonnes, {'TypePersonne': 'P', 'RoleConducteur': 'P'}) : {}

    return {
        data: getFormValues('devis')(state),
        postalCodeCity: ville ? `${ville} ${cp}` : '',
        conducteurEstSouscripteur: conducteurPrincipal ? String(conducteurPrincipal.Souscripteur) : getPersonnePrincipaleIsSouscripteurDemandeTarifInit(state),
        titulaireCarteGrise: selector(state, 'titulaireCarteGrise'),
        isDevisOrange: getState(state) === 'devis_orange'
    }
}


const mapDispatchToProps = dispatch => bindActionCreators({
    changeValue: (field, value) => change('devis', field, value)
}, dispatch)


const RecontactContainer = compose(
    connect(mapStateToProps, mapDispatchToProps)
)(Recontact)


export default RecontactContainer
