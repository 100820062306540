import{useEffect, useState} from 'react';
import PropTypes from 'prop-types'
import Options from "./Options/Options";
import OptionPrix from "./OptionPrix/OptionPrix";
import {Field} from "redux-form";
import {Icons} from "@amo/core/components/containers";
import {RadioButtonOpt} from "@amo/core/components/forms";
import _ from "lodash";


const BlocOption = (props) => {
    const {option, selected, colLg, fractionnement, icon} = props
    const [titre, setTitre] = useState(null);
    const [text, setText] = useState(null);
    //Permet de gérer les listeOptions
    const sousInput = option.data[0].listeTarifOption && option.idGroupe === 'ACCESSEQP' ? option.data[0].listeTarifOption : false;

    useEffect(() => {
        changeTitleTexte(!sousInput && selected !== '' ? selected : undefined)

    }, [selected])

    const changeTitleTexte = (value = _.head(option.data).value) => {
        const newVal = _.find(option.data, ['value', value]);

        if(newVal && newVal.dataAffichage) {
            setTitre(newVal.dataAffichage.titre);
            setText(newVal.dataAffichage.text);
        }
    }

    return (
        <div className={`col-12 ${colLg} mb-3 mb-lg-4 blocContainerOption`}>
            <Options icon={icon} title={option.labelGroupe} selected={selected}
                     footer={
                        <Field name={sousInput ? `optionsListe[${option.idGroupe}]` : `options[${option.idGroupe}]`} component={RadioButtonOpt} footer data={[{
                            value: "",
                            label: option.nonSouscrit ? option.nonSouscrit : 'Je ne souhaite pas souscrire à cette option'
                        }]}/>
                     }
                >
                <div className={'p-3 blocOptionContent'}>

                    { option.titre && <div className={'f-14 d-block mb-1'} style={{fontWeight: 600}} dangerouslySetInnerHTML={{__html: option.titre}}/> }
                    { option.textePrincipal && <div className={'f-12 d-block mb-3'} dangerouslySetInnerHTML={{__html: option.textePrincipal}}/> }

                    {
                        option.liste &&
                        <ul className="list-unstyled">
                            {
                                _.map(option.liste, (o, i) => (
                                    <li key={i} className={'d-flex align-items-center f-14 mb-3'}><Icons fontSize={8} icon={'Shape'} className={'mr-2 text-primary'}/>
                                        {o.value}
                                    </li>
                                ))
                            }
                        </ul>
                    }

                    <Field
                        name={sousInput ? `optionsListe[${option.idGroupe}]` : `options[${option.idGroupe}]`}
                        component={RadioButtonOpt}
                        className={`mb-4`}
                        data={sousInput ? sousInput : option.data}
                    />

                    { titre && text &&
                        <>
                            <div
                                className={'f-14 text-uppercase text-center text-primary-light font-weight-bold mb-3'}>{titre}</div>
                            <div className={'text-center f-12 mb-3'} dangerouslySetInnerHTML={{__html: text}}/>
                        </>
                    }

                    <div className={'px-4 py-3 bg-secondary-light f-14 text-center'}>
                        <div
                            className={'text-uppercase text-primary font-weight-bold mb-3 d-flex align-items-center justify-content-center'}>
                            <Icons fontSize={26} icon={'trophy'} className={'mr-3'}/> Les + APRIL Moto :
                        </div>

                        { option.plusApril && <div dangerouslySetInnerHTML={{__html: option.plusApril}}/> }

                        <div className={'text-primary-light f-21 font-weight-bold'}>
                            <OptionPrix valueSelected={selected} values={sousInput ? sousInput : option.data} fractionnement={fractionnement}/>
                        </div>
                    </div>

                </div>
            </Options>
        </div>
    );
};


BlocOption.defaultProps = {
    selected: '',
    colLg: 'col-lg-12'
};


BlocOption.propTypes = {
    selected: PropTypes.string,
    colLg: PropTypes.string,
    option: PropTypes.object.isRequired
};


export default BlocOption;
