import {connect} from 'react-redux'
import Antecedents from "./Antecedents";
import formValueSelector from "redux-form/lib/formValueSelector";
import {bindActionCreators, compose} from "redux";
import {change} from "redux-form";

const mapStateToProps = state => {
    const selector = formValueSelector('devis')

    const malusAuto = selector(state, 'DemandeTarif[ListePersonnes][0][ValeurCrmAuto]');
    const malusMoto = selector(state, 'DemandeTarif[ListePersonnes][0][ValeurCrmMoto]');

    return {
        sinistres: selector(state, 'DemandeTarif[Antecedents][ListeSinistres]'),
        sinistre_auto: selector(state, 'sinistre_auto'),
        resiliationAssureur: selector(state, 'DemandeTarif[Antecedents][ResiliationAssureur]'),
        annulationPermis: selector(state, 'DemandeTarif[Antecedents][AnnulationPermis]'),
        suspensionPermis: selector(state, 'DemandeTarif[Antecedents][SuspensionPermis]'),
        condamnationAlcoolemie: selector(state, 'DemandeTarif[Antecedents][CondamnationAlcoolemie]'),
        cylindreeVehicule: selector(state, 'cylindreeVehicule'),
        familleProduit: selector(state, 'typeVehicule'),
        assure_moto: selector(state, 'assure_moto'),
        assure_auto: selector(state, 'assure_auto'),
        nbMoisAssuranceMoto: selector(state, 'DemandeTarif[ListePersonnes][0][NbMoisAssuranceMoto]'),
        nbMoisAssuranceAuto: selector(state, 'DemandeTarif[ListePersonnes][0][NbMoisAssuranceAuto]'),
        valeurCrmMoto: selector(state, 'DemandeTarif[ListePersonnes][0][ValeurCrmMoto]'),
        valeurCrmAuto: selector(state, 'DemandeTarif[ListePersonnes][0][ValeurCrmAuto]'),
        typePermisB: selector(state, 'DemandeTarif[ListePersonnes][0][ListePermis][4][TypePermis]'),
        malusAutoMoto: (malusAuto ? Number(malusAuto) > 1 : false) || (malusMoto ? Number(malusMoto) > 1 : false),
        dejaClientAntecedents: selector(state, 'DemandeTarif[ListePersonnes][0][DejaClientAntecedents]'),
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    changeValue: (field, value) => change('devis', field, value),
}, dispatch)

const AntecedentsContainer = compose(
    connect(mapStateToProps, mapDispatchToProps)
)(Antecedents)

export default AntecedentsContainer
