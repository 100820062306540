import { API_URL_WS_FORMULAIRE } from '@amo/core/constants';

export const INIT = '[Init]'

export const SET_INIT = `${INIT} Set`
export const POST_INIT = `${INIT} Post`

export const postInit = ({token, key}) => ({
    type: POST_INIT,
    payload: {
        data: `${API_URL_WS_FORMULAIRE}/init`,
        body: {token, key}
    }
})

export const setInit = ({init}) => ({
    type: SET_INIT,
    payload: {
        data: init
    }
})
