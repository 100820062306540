import PropTypes from 'prop-types'
import {Icons} from "@amo/core/components/containers";
import './option.scss'


const Options = (props) => {
    const { icon, title, children, footer, selected } = props

    return (
        <div className={`tarifOption border rounded h-100 position-relative ${ footer && 'mb-footer' }`}>
            <div className={`p-4 d-flex align-items-center ${ selected ? 'text-white bg-primary' : 'bg-grey-light text-initial' }`}>
                {icon && <Icons className={`d-inline-block mr-4  ${ selected ? 'text-white' : 'text-initial' }`} icon={icon} fontSize={37} />}
                <div className={'d-inline-block text-uppercase font-weight-bold f-14'}>{title}</div>
            </div>
            {children}

            { footer &&
                <div className={'tarifFooter'}>
                    {footer}
                </div>
            }
        </div>
    );
};


Options.defaultProps = {
    icon: '',
    children: '',
    footer: '',
    selected: false
};


Options.propTypes = {
    icon: PropTypes.string,
    title: PropTypes.string.isRequired,
    children: PropTypes.node,
    footer: PropTypes.node,
    selected: PropTypes.oneOfType([PropTypes.bool, PropTypes.string])
};


export default Options;