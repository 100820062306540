import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'
import Vehiculier from "./Vehiculier.jsx";
import {change, formValueSelector} from "redux-form";
import {getLoadingEntity} from "../../../../../redux/selectors/ui/ui.selectors.js";
import {FETCH_MARQUES_VEHICULIER} from "../../../../../redux/actions/app/vehiculier/vehiculier.actions.js";

const mapStateToProps = state => {
    const selector = formValueSelector('devis')

    return {
        typeVehicule: selector(state, `typeVehicule`),
        marque: selector(state, `marqueVehicule`),
        cylindree: selector(state, `cylindreeVehicule`),
        is50: selector(state, `typeVehicule`) === '50' ,
        loadedMarques: getLoadingEntity(state, FETCH_MARQUES_VEHICULIER) === false
    }
}


const mapDispatchToProps = dispatch => bindActionCreators({
    changeValue: (field, value) => change('devis', field, value)
}, dispatch)

const VehiculierContainer = connect(mapStateToProps, mapDispatchToProps) (Vehiculier)


export default VehiculierContainer
