import { API_URL_WHEELS } from '@amo/core/constants';

export const VEHICULE = '[Vehicule]';

export const GET_VEHICULE = `${VEHICULE} Get Vehicule`;
export const SET_VEHICULE = `${VEHICULE} Set Vehicule`;
export const POST_VEHICULE_IS_DISABLED = `${VEHICULE} Post Vehicule Is Disabled`;

export const getVehicule = ({idSra}) => ({
    type: GET_VEHICULE,
    payload: {
        data: `${API_URL_WHEELS}/vehicles/${idSra}`
    }
})

export const setVehicule = ({data}) => ({
    type: SET_VEHICULE,
    payload: {
        data
    }
})

export const postVehiculeIsDisabled = ({log}) => ({
    type: POST_VEHICULE_IS_DISABLED,
    payload: {
        data: `${API_URL_WHEELS}/vehicles/log`,
        body: log
    }
})
