import {API_ERROR, API_SUCCESS, apiRequest} from '../../../actions/core/api/api.actions'
import {setError, setLoader} from '../../../actions/app/ui/ui.actions'
import {formatDataForApi} from "../fill/formatDataForApi";
import {POST_PAY} from "../../../actions/app/pay/pay.action";
import {setTrackAction} from "../../../actions/core/analytics/analytics.actions";

export const payMiddleware = dispatch => next => action => {
    next(action)

    const { payload } = action

    switch (action.type){

        case POST_PAY:
            const body = formatDataForApi(payload.body)

            next([
                apiRequest({body, method: 'POST', url: payload.data, entity: POST_PAY}),
                setLoader({state: true, entity: POST_PAY})
            ])

            break

        case `${POST_PAY} ${API_SUCCESS}`:
            next([
                setError({state: false, entity: POST_PAY}),
                setLoader({state: false, entity: POST_PAY}),
                setTrackAction({event: 'click', actionGoogle: 'clickButton', libelle: 'Je finalise la souscription', categorie: 'GO-TO-PAY', titleMatomo: 'clickButton', valueMatomo: 'Je finalise la souscription'})
            ])

            window.location = payload.data
            break

        case `${POST_PAY} ${API_ERROR}`:
            next([
                setError({state: true, entity: POST_PAY}),
                setLoader({state: false, entity: POST_PAY})
            ])
            break

        default:
            return null
    }
}
