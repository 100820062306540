import { Text } from '@amo/core/components/forms';
import { SmallSpinner } from '@amo/core/components/loaders';
import { getVehiculeWheelsByType } from '@amo/core/utils/index.js';
import { useOnClickOutside } from '@amo/core/utils/hooks.js';
import Fuse from 'fuse.js';
import _ from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { Field } from 'redux-form';
import './searchBarVehicule.scss';

const SearchBarVehicule = (props) => {
    const { changeValue, typeVehicule } = props;
    const [result, setResult] = useState([]);
    const [vehiclesWheels, setVehiclesWheels] = useState([]);
    const ref = useRef();
    useOnClickOutside(ref, () => setResult([]));

    useEffect(() => {
        if(typeVehicule) {
            fetchVehiculeWheels(typeVehicule);
        }
    }, [typeVehicule]);

    async function fetchVehiculeWheels(value) {
        setVehiclesWheels('pending');

        if(value !== undefined) {
            let vehicules = await getVehiculeWheelsByType(value);

            _.map(vehicules, (item) => {
                item.libelle = `${item.brand.name}${item.modele ? ` ${item.modele}` : ''}${
                    item.version && item.cylindree > 50 ? ` ${item.version}` : ''
                } ${item.cylindree}`;
                item.search = `${item.brand.name}${item.modele ? ` ${item.modele}` : ''} ${item.cylindree}`;
                item.date = `${
                    item.annee_debut_version !== null && item.annee_debut_version !== 0
                        ? `depuis ${item.annee_debut_version}`
                        : ''
                } ${
                    item.annee_fin_version !== null && item.annee_fin_version !== 0
                        ? ` jusqu'a ${item.annee_fin_version}`
                        : ''
                }`;
            });
            setVehiclesWheels(vehicules);
        }
    }

    const options = {
        shouldSort: true,
        minMatchCharLength: 1,
        location: 0,
        threshold: 0.6,
        distance: 100,
        keys: ['search'],
    };

    const fuse = Array.isArray(vehiclesWheels) ? new Fuse(vehiclesWheels, options) : null;

    const selectVehicule = (vehicule) => {
        if (vehicule.cylindree === 50) {
            changeValue(`modeleVehicule`, vehicule.modele);
        } else {
            let date = `${
                vehicule.annee_debut_version !== null && vehicule.annee_debut_version !== 0
                    ? `depuis ${vehicule.annee_debut_version}`
                    : ''
            } ${
                vehicule.annee_fin_version !== null && vehicule.annee_fin_version !== 0
                    ? ` jusqu'a ${vehicule.annee_fin_version}`
                    : ''
            }`;
            changeValue(
                `modeleVehicule`,
                `${vehicule.modele} ${vehicule.version} <small class="d-block">${date}</small>`,
            );
        }

        changeValue(`code_categorie`, vehicule.codecategorie);
        changeValue(`marqueVehicule`, vehicule.brand.name);
        changeValue(`cylindreeVehicule`, vehicule.cylindree);
        changeValue('DemandeTarif[Vehicule][IdentifiantSRA]', vehicule.sra);
        changeValue('typeVehicule', vehicule.type.id);
        changeValue('fuse-find-modele', vehicule.libelle);
        setResult([]);
    };

    return (
        <div className={'col-md-7 mt-2 mt-md-2'} id={'search-bar'} ref={ref}>
            {vehiclesWheels === 'pending' ? (
                <SmallSpinner />
            ) : (
                <>
                    <Field
                        component={Text}
                        name={'fuse-find-modele'}
                        onChange={(e) => setResult(_.take(fuse.search(e.target.value), 8))}
                        placeholder={'Rechercher votre modèle'}
                    />

                    {_.map(result, (r) => (
                        <div
                            key={r.item.sra}
                            className={'p-2 cursor-pointer border border-top-0 item'}
                            onClick={() => selectVehicule(r.item)}
                        >
                            {r.item.libelle} - <span className={'f-14 font-italic'}>{r.item.date}</span>
                        </div>
                    ))}

                    <p className="m-0 text-grey">
                        <small>
                            Commencez par la <b>marque</b>, le <b>modèle</b> et éventuellement la <b>cylindrée</b> pour
                            trouver votre véhicule
                        </small>
                    </p>
                </>
            )}
        </div>
    );
};

export default SearchBarVehicule;
