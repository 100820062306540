import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import Init from "./Init";
import {INIT, postInit} from "../../../redux/actions/app/init/init.actions";
import {localStorageAMO} from "../../../redux/selectors/localStorage/localStorage.selectors";
import {getError, getErrorEntity, getLoadingEntity} from "../../../redux/selectors/ui/ui.selectors";
import {loader} from "@amo/core/components/loaders";
import {getSlugStep} from "../../../utils/function";
import {VEHICULE} from "../../../redux/actions/app/vehicule/vehicule.actions";
import _ from 'lodash'
import RouteStep from "./InitFormulaire/RouteStep";

const mapStateToProps = state => {
    return {
        loaded: getLoadingEntity(state, INIT) === false && getLoadingEntity(state, VEHICULE) !== true,
        stepTarif: getSlugStep('votre-tarif'),
        stepValidation: getSlugStep('validation'),
        stepInformationsConducteur: getSlugStep('vos-infos'),
        stepProfil: getSlugStep('votre-profil'),
        stepRecapitulatif: getSlugStep('recapitulatif'),
        stepPaiement: getSlugStep('paiement'),
        stepRecontact: getSlugStep('recontact'),
        error: getError(state) && getErrorEntity(state, INIT),
        state
    }
}

const mapDispatchToProps = (dispatch, ownProps) => bindActionCreators({
    load: () => postInit({
        token: localStorageAMO && localStorageAMO.token,
        key: !_.find(RouteStep, ['path', '/' + ownProps.cle]) ? ownProps.cle : null
    })
}, dispatch)

const InitContainer = compose(
    connect(mapStateToProps, mapDispatchToProps),
    loader()
)(Init)

export default InitContainer
