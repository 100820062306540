import {initMiddleware} from "./init/init.middleware";
import {fillMiddleware} from "./fill/fill.middleware";
import {tarifMiddleware} from "./tarif/tarif.middleware";
import {saveDevisMiddleware} from "./saveDevis/saveDevis.middleware";
import {demandeRappelMiddleware} from "./demandeRappel/demandeRappel.middleware";
import {checkContratStatutMiddleware} from "./checkContratStatut/checkContratStatut.middleware";
import {vehiculierMiddleware} from "./vehiculier/vehiculier.middleware";
import {vehiculeMiddleware} from "./vehicule/vehicule.middleware";
import {payMiddleware} from "./pay/pay.middleware";
import {notFindVehiculeMiddleware} from "./notFindVehicule/notFindVehicule.middleware";
import {contactMiddleware} from "./contact/contact.middleware";
import {dejaClientMiddleware} from "./dejaClient/dejaClient.middleware";
import {askQuestionMiddleware} from "./askQuestion/askQuestion.middleware";


export const appMiddleware = [
    initMiddleware,
    fillMiddleware,
    payMiddleware,
    tarifMiddleware,
    saveDevisMiddleware,
    demandeRappelMiddleware,
    checkContratStatutMiddleware,
    vehiculeMiddleware,
    vehiculierMiddleware,
    notFindVehiculeMiddleware,
    contactMiddleware,
    dejaClientMiddleware,
    askQuestionMiddleware
]
