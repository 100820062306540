import { useEffect } from 'react';
import FormRecontact from './FormRecontact/FormRecontact';

const Recontact = (props) => {
    const { isDevisOrange, changeValue } = props;

    useEffect(() => {
        changeValue('DemandeDevis[TypeMER]', 'DevisOrange');
    }, []);

    return (
        <div>
            {isDevisOrange ? (
                <div className={'bg-secondary-light p-4 rounded text-left mb-4'}>
                    <p>Merci pour vos informations, l’un de nos conseillers va vous contacter prochainement.</p>
                </div>
            ) : (
                <FormRecontact {...props} />
            )}
        </div>
    );
};

export default Recontact;
