import './header.scss'

const HeaderError = () => {
    return (
        <div className={'header header-error'}>
            <div className={'text-center m-auto position-relative container'}>
                <div className={'row'}>
                    <div className={'col-md-6 offset-md-3 bg-white bg-white pt-5 pb-5'}>
                        <p className="header-title">Le formulaire n'est pas disponible, merci de l'ouvrir à nouveau via votre outil de gestion</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HeaderError;
