import { API_URL_WHEELS } from '@amo/core/constants';

export const NOT_FIND_VEHICULE = '[Je ne trouve pas mon véhicule]'

export const POST_NOT_FIND_VEHICULE = `${NOT_FIND_VEHICULE} Post`

export const postNotFindVehicule = ({form}) => ({
    type: POST_NOT_FIND_VEHICULE,
    payload: {
        data: `${API_URL_WHEELS}/vehicles/request`,
        body: form
    }
})
