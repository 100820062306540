import { Collapse, LinkButton } from '@amo/core/components/containers';
import moment from 'moment';
import PropTypes from 'prop-types';

const SideBarContent = (props) => {
    const { tabContent, isCurrentStep, icon, title, slug, goTo } = props;
    let isEmpty = true;

    return (
        <Collapse title={title} icon={icon} isCollapse={true}>
            <div className="bg-secondary-light p-3 rounded w-full">
                {tabContent.map((row, index) => {
                    const valid_value =
                        (row.value && (!moment.isMoment(row.value) || moment(row.value)._isValid)) || row.value === 0;

                    if (isEmpty && valid_value) {
                        isEmpty = false;
                    }

                    return valid_value ? (
                        <div key={index} className={'mb-2'}>
                            {
                                row.type === "img" ? <img src={row.value} alt="Logo" width={70}/>
                                    : <>
                                        <p className={'m-0 font-weight-bold'}>{row.title}&nbsp;:</p>
                                        <p
                                            className={'m-0'}
                                            dangerouslySetInnerHTML={{
                                                __html: moment.isMoment(row.value) ? row.value.format('L') : row.value,
                                            }}
                                        />
                                    </>
                            }
                        </div>
                    ) : (
                        ''
                    );
                })}
                {isEmpty && (
                    <div>
                        <p className={'m-0'}>Complétez le formulaire</p>
                    </div>
                )}

                {!isCurrentStep && (
                    <div className={'text-right'}>
                        <LinkButton
                            text={'Modifier'}
                            right
                            icon={'pen'}
                            iconProps={{ fontSize: 13 }}
                            onClick={() => goTo(slug)}
                        />
                    </div>
                )}
            </div>
        </Collapse>
    );
};

Collapse.defaultProps = {
    icon: '',
};

SideBarContent.propTypes = {
    tabContent: PropTypes.array.isRequired,
    isCurrentStep: PropTypes.bool.isRequired,
    icon: PropTypes.string,
    title: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    goTo: PropTypes.func,
};

export default SideBarContent;
