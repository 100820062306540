import HeaderLanding from "./HeaderLanding";
import {formValueSelector} from "redux-form";
import {connect} from "react-redux";

const mapStateToProps = state => {
    const selector = formValueSelector('devis')
    return {
        marqueVehicule: selector(state, `marqueVehicule`)
    }
}


const HeaderLandingContainer = connect(mapStateToProps)(HeaderLanding)


export default HeaderLandingContainer