import lbc from "../../../../../../assets/images/landing/leboncoin.png";
import illustration from "../../../../../../assets/images/landing/femme-casque-moto.png";
import motards from "../../../../../../assets/images/landing/motards.png";
import services from "../../../../../../assets/images/landing/services.png";
import avance from "../../../../../../assets/images/landing/avance.png";
import indem from "../../../../../../assets/images/landing/indem.png";
import logo67256 from "../../../../../../assets/images/landing/67256.png";
import logo100122 from "../../../../../../assets/images/landing/100122.png";
import logo101853 from "../../../../../../assets/images/landing/101853.png";
import logo393798 from "../../../../../../assets/images/landing/393798.png";


const HeaderLandingMobile = (props) => {
    const {IDDevis, apporteur2, utm_source, marqueVehicule} = props

    const logoApporteur2 = {
        "67256" : logo67256,
        "100122" : logo100122,
        "101853" : logo101853,
        "393798" : logo393798,
    };

    return (
        <div className={'container f-16'}>
            <p>Bonjour, bienvenue chez APRIL&nbsp;Moto.</p>
            <p>Comme vous, <span className={'font-weight-bold'}>500 000 motards nous ont déjà fait confiance.</span></p>
            <div className={'illustration m-auto'}>
                <img src={illustration} alt="" className={'w-100 h-100'}/>
            </div>
            {
                apporteur2 || (utm_source && utm_source === "lbc") ?
                    <div className={'mb-4'}>
                        <div
                            className={'infos-devis d-flex rounded bg-white align-items-center p-3 mr-3 justify-content-between'}>
                            <div className={'pr-3'}>
                                <p className={'m-0 font-weight-bold'}>Votre devis{!IDDevis ? ' avec :' : ''}</p>
                                {
                                    IDDevis ? <p className={'m-0'}>N° {IDDevis} avec&nbsp;:</p> : ''
                                }
                            </div>

                            <div className={'logo-landing'}>
                                <img src={apporteur2 ? logoApporteur2[apporteur2] : lbc} className={'w-100 h-100'}
                                     alt={'logo'}/>
                            </div>
                        </div>
                    </div>
                    : ''
            }
            <div className={'text-center'}>
                <p className={'f-21'}>Vous êtes au meilleur endroit pour assurer votre {marqueVehicule ? <span className="text-orange">{marqueVehicule}</span> : 'véhicule'} !</p>
                <p className={'font-weight-bold'}>Dans 3 clics, vous aurez <span className={'text-orange'}>votre attestation d'assurance</span> !</p>

                <span className={'btn-start'}>
                    <span className={'btn btn-primary btn-sm medium-rounded mt-4 mb-5'} onClick={() => document.getElementById('formulaire').scrollIntoView({behavior: 'smooth'})} data-id="btn-start">C'est parti !</span>
                </span>
            </div>

            <div className="row">
                <div className="col-10 offset-1">
                    <div className="row font-weight-bold bloc-reassurance">
                        <div className="col-12 col-md-6 mb-5">
                            <div className="d-flex justify-content-left">
                                <div><img src={services} alt="icone téléphone portable" className={'mr-4'} width={55}/></div>
                                <p className={'m-0 d-inline-block'}>Un service client humain et&nbsp;disponible</p>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 mb-5">
                            <div className="d-flex justify-content-left">
                                <div><img src={motards} alt="icone casque moto" className={'mr-4'} width={45}/></div>
                                <p className={'m-0 d-inline-block'}>Pour tous les motards et tous les&nbsp;deux-roues</p>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 mb-5">
                            <div className="d-flex justify-content-left">
                                <div><img src={indem} alt="icone parapluie" className={'mr-4'} width={45}/></div>
                                <p className={'m-0 d-inline-block'}>Jusqu'à 1,2&nbsp;million d'euros&nbsp;indemnisés</p>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 mb-3">
                            <div className="d-flex justify-content-left">
                                <div><img src={avance} alt="icone main" className={'mr-4'} width={45}/></div>
                                <p className={'m-0 d-inline-block'}>Pas d'avance d'argent en&nbsp;cas de&nbsp;sinistre</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};


export default HeaderLandingMobile;