import { Text } from '@amo/core/components/forms';
import _ from 'lodash';
import { useState } from 'react';
import { Fade } from 'reactstrap';
import { Field } from 'redux-form';
import '../../vehiculier.scss';
import SelectModeleContainer from './SelectModele/SelectModeleContainer.js';
import { QuestionLabel } from '@amo/core/components/containers';

const SelectCylindree = (props) => {
    const { cylindreeSelected, marque_id, changeValue, setVehiculierAffiche, cylindrees, type } = props;
    const [listCylindrees, setListCylindreesFilter] = useState(cylindrees);

    const filterArrayCylindreesBySearch = (value) => {
        let filterBySearch = _.filter(cylindrees, (c) => {
            return _.includes(_.toString(c), value);
        });

        setListCylindreesFilter(filterBySearch);
    };

    return (
        <Fade>
            {!cylindreeSelected ? (
                <div className={'row align-items-center'}>
                    <QuestionLabel className={'f-14 mt-0 mb-2 col-12  col-md-6'}>Selectionnez la cylindrée :</QuestionLabel>

                    <div className={'col-12 col-md-6'}>
                        <Field
                            component={Text}
                            name={'find-cylindree'}
                            placeholder={'Recherchez votre cylindrée'}
                            onChange={(e) => filterArrayCylindreesBySearch(e.target.value)}
                        />
                    </div>

                    {listCylindrees.map((cylindree, index) => (
                        <div
                            key={index}
                            onClick={() => changeValue('cylindreeVehicule', cylindree)}
                            className={'col-6 col-md-4 mt-3 mt-md-2'}
                        >
                            <div className={'blocContainer border rounded text-center h-100 cursor-pointer p-3'}>
                                <span>{cylindree}</span>
                            </div>
                        </div>
                    ))}
                </div>
            ) : (
                <SelectModeleContainer
                    type={type}
                    marque_id={marque_id}
                    cylindree={cylindreeSelected}
                    setVehiculierAffiche={setVehiculierAffiche}
                />
            )}
        </Fade>
    );
};

SelectCylindree.defaultProps = {};

SelectCylindree.propTypes = {};

export default SelectCylindree;
