import {API_ERROR, API_SUCCESS, apiRequest} from '../../../actions/core/api/api.actions'
import {setError, setErrorTarif, setLoader} from '../../../actions/app/ui/ui.actions'
import {
    FETCH_CYLINDREES_VEHICULIER, FETCH_MARQUES_VEHICULIER, FETCH_MODELES_VEHICULIER,
    setCylindreesVehicule, setMarquesVehicule, setModelesVehicule
} from "../../../actions/app/vehiculier/vehiculier.actions";

export const vehiculierMiddleware = () => next => action => {
    next(action)

    const { payload } = action

    switch (action.type){

        case FETCH_CYLINDREES_VEHICULIER:
        case FETCH_MARQUES_VEHICULIER:
        case FETCH_MODELES_VEHICULIER:
            next([
                apiRequest({body: null, method: 'GET', url: payload.data, entity: action.type}),
                setLoader({state: true, entity: action.type})
            ]);
            break;

        case `${FETCH_CYLINDREES_VEHICULIER} ${API_SUCCESS}`:
        case `${FETCH_MARQUES_VEHICULIER} ${API_SUCCESS}`:
        case `${FETCH_MODELES_VEHICULIER} ${API_SUCCESS}`:
            let nextAction = [
                setError({state: false, entity: payload.meta.entity}),
                setLoader({state: false, entity: payload.meta.entity})
            ]

            switch (payload.meta.entity) {
                case FETCH_CYLINDREES_VEHICULIER:
                    nextAction.unshift(setCylindreesVehicule({data: payload.data.data}))
                    break;
                case FETCH_MARQUES_VEHICULIER:
                    nextAction.unshift(setMarquesVehicule({data: payload.data.data}))
                    break;

                case FETCH_MODELES_VEHICULIER:
                    nextAction.unshift(setModelesVehicule({data: payload.data.data}))
                    break;

                default:
                    return null
            }

            next(nextAction);
            break;

        case `${FETCH_CYLINDREES_VEHICULIER} ${API_ERROR}`:
        case `${FETCH_MARQUES_VEHICULIER} ${API_ERROR}`:
        case `${FETCH_MODELES_VEHICULIER} ${API_ERROR}`:
            next([
                setErrorTarif({data: payload.data.response.data.error}),
                setError({state: true, entity: payload.meta.entity}),
                setLoader({state: false, entity: payload.meta.entity})
            ]);
            break;


        default:
            return null
    }
}
