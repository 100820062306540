import {connect} from 'react-redux'
import {compose} from 'redux'
import SearchBarVehicule from "./SearchBarVehicule.jsx";
import {change} from "redux-form";
import formValueSelector from "redux-form/lib/formValueSelector";

const mapStateToProps = state => {
    const selector = formValueSelector('devis')

    return {
        typeVehicule: selector(state, `typeVehicule`)
    }
}

const mapDispatchToProps = {
    changeValue: (field, value) => change('devis', field, value)
}

const SearchBarVehiculeContainer = compose(
    connect(mapStateToProps, mapDispatchToProps)
)(SearchBarVehicule)

export default SearchBarVehiculeContainer
