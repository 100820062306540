import { APP_URL_PASS } from '@amo/core/constants';

export const CONTACT = '[Contact]'
export const POST_CONTACT = `${CONTACT} Post`

export const postContact = ({form}) => ({
    type: POST_CONTACT,
    payload: {
        data: `${APP_URL_PASS}/contact`,
        body: form
    }
})
