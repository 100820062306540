export const TARIF = '[Tarif]'
export const TARIF_RELOAD = '[TarifReload]'

export const POST_TARIF = `${TARIF} Post`
export const POST_TARIF_RELOAD = `${TARIF_RELOAD} Post`
export const SET_TARIF = `${TARIF} Set`

export const postTarif = ({form, query}) => ({
    type: POST_TARIF,
    payload: {
        data: query,
        body: form
    }
})

export const setTarif = ({data}) => ({
    type: SET_TARIF,
    payload: {
        data
    }
})

export const postReloadTarif = ({form, query}) => ({
    type: POST_TARIF_RELOAD,
    payload: {
        data: query,
        body: form
    }
})
