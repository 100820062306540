import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'
import HeaderTarif from "./HeaderTarif";
import {formValueSelector, change} from "redux-form";
import {
    getFormuleLibelle, getGarantiesOption,
    getInfoCodePromo,
} from "../../../../../redux/selectors/tarif/tarif.selectors";
import {
    getErrorEntity, getLoadingEntity,
    getPrixTotal,
    getPrixTotalAnnuel, getPrixTotalAnnuelPromo,
    getPrixTotalPromo
} from "../../../../../redux/selectors/ui/ui.selectors";
import {TARIF} from "../../../../../redux/actions/app/tarif/tarif.actions";
import _ from 'lodash';

const mapStateToProps = state => {
    const selector = formValueSelector('devis')
    const formuleChoisie = selector(state, 'DemandeTarif[Police][FormuleChoisie]')
    const garantiesOptionDispo = getLoadingEntity(state, TARIF) === false ? getGarantiesOption(state, formuleChoisie) : [];

    const options = _.filter(selector(state, 'options'), item => { return item !== undefined && _.find(garantiesOptionDispo, ['CodeGarantie', item]) })
    const optionsListe = _.filter(selector(state, 'optionsListe'), (item, key) => {
        return item !== undefined && item !== '' && _.find(garantiesOptionDispo, ['CodeGarantie', key]);
    });
    return {
        marque: selector(state, `marqueVehicule`),
        fractionnement: selector(state, `DemandeTarif[Police][FractionnementChoisi]`),
        codePromo: getInfoCodePromo(state),
        error: getErrorEntity(state, TARIF),
        prixTotal: getPrixTotal(state),
        prixTotalPromo: getPrixTotalPromo(state),
        prixTotalAnnuel: getPrixTotalAnnuel(state),
        prixTotalAnnuelPromo: getPrixTotalAnnuelPromo(state),
        libelleFormule: getFormuleLibelle(state, formuleChoisie),
        nbOptions: options && _.isArray(options) ? options.length + (optionsListe && _.isArray(optionsListe) ? optionsListe.length : 0) : 0,
        loadingCodePromo: getLoadingEntity(state, TARIF)
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    changeValue: (field, value) => change('devis', field, value)
}, dispatch)

const HeaderTarifContainer = connect(mapStateToProps, mapDispatchToProps)(HeaderTarif)

export default HeaderTarifContainer
