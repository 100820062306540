import {BrowserRouter, Route, Routes} from 'react-router-dom';
import GetParams from "./GetParams";

const App = () => {
    return (
        <div>
            <BrowserRouter>
                <Routes>
                    <Route exact path='/'>
                        <Route path=':key?' element={<GetParams />} />
                    </Route>
                </Routes>
            </BrowserRouter>
        </div>
    )
}

export default App
