import {apiMiddleware} from './api/api.middleware'
import {actionSplitterMiddleware} from './actionSplitter/actionSplitter'
import {normalizeMiddleware} from './normalize/normalize.middleware'
import {analyticsMiddleware} from './analytics/analytics.middleware'
import {localStorageMiddleware} from "./localStorage/localStorage.middleware";
import {notificationsMiddleware} from "./notifications/notifications.middleware";

export const coreMiddleware = [
    actionSplitterMiddleware,
    apiMiddleware,
    normalizeMiddleware,
    localStorageMiddleware,
    analyticsMiddleware,
    notificationsMiddleware
]
