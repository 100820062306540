import {connect} from 'react-redux'
import {getMaxStep, getStep} from "../../../../../redux/selectors/ui/ui.selectors";
import SideBar from "./SideBar";
import {getSlugStep} from "../../../../../utils/function";
import {postFill} from "../../../../../redux/actions/app/fill/fill.actions";
import {getFormValues} from "redux-form";
import _ from "lodash";
import RouteStep from "../RouteStep";
import { API_URL_WS_FORMULAIRE } from '@amo/core/constants';
import {getFeuTricoloreTarifInit, getToken} from "../../../../../redux/selectors/init/init.selectors";

const mapStateToProps = state => {
    return {
        form: getFormValues('devis')(state),
        maxStep: getMaxStep(state),
        query: `${API_URL_WS_FORMULAIRE}/fill/${getToken(state)}`,
        step: getStep(state),
        stepVehicule: getSlugStep('votre-vehicule'),
        stepInformationsVehicule: getSlugStep('informations'),
        stepProfil: getSlugStep('votre-profil'),
        stepAntecedents: getSlugStep('vos-antecedents'),
        stepBesoins: getSlugStep('vos-besoins'),
        stepDevis: getSlugStep('votre-tarif'),
        isDevisOrange: getFeuTricoloreTarifInit(state) === 'Orange',
    }
}

const mapDispatchToProps = {
    postFill
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { form, maxStep, query } = stateProps
    const { postFill } = dispatchProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        postFill: (slug) => postFill({step: _.find(RouteStep, ['slug', slug]).step, maxStep, form, query})
    }
}

const SideBarContainer = connect(mapStateToProps, mapDispatchToProps, mergeProps)(SideBar)


export default SideBarContainer
