import { API_URL_WHEELS } from '@amo/core/constants';

export const VEHICULIER = '[Vehiculier]';

export const FETCH_MARQUES_VEHICULIER = `${VEHICULIER} Fetch Marques`;
export const FETCH_CYLINDREES_VEHICULIER = `${VEHICULIER} Fetch Cylindrees`;
export const FETCH_MODELES_VEHICULIER = `${VEHICULIER} Fetch Modeles`;

export const SET_MARQUES_VEHICULIER = `${VEHICULIER} Set Marques`;
export const SET_CYLINDREES_VEHICULIER = `${VEHICULIER} Set Cylindrees`;
export const SET_MODELES_VEHICULIER = `${VEHICULIER} Set Modeles`;

export const fetchMarquesVehicule = ({type}) => ({
    type: FETCH_MARQUES_VEHICULIER,
    payload: {
        data: `${API_URL_WHEELS}/brands?type=${type}`
    }
})

export const fetchCylindreeVehicule = ({type, marque_id}) => ({
    type: FETCH_CYLINDREES_VEHICULIER,
    payload: {
        data: `${API_URL_WHEELS}/sizes?type=${type}&brand_id=${marque_id}`
    }
})

export const fetchModelesVehicule = ({type, marque_id, cylindree}) => ({
    type: FETCH_MODELES_VEHICULIER,
    payload: {
        data: `${API_URL_WHEELS}/vehicles?type=${type}&brand_id=${marque_id}&size=${cylindree}`
    }
})

export const setMarquesVehicule = ({data}) => ({
    type: SET_MARQUES_VEHICULIER,
    payload: {
        data
    }
})

export const setCylindreesVehicule = ({data}) => ({
    type: SET_CYLINDREES_VEHICULIER,
    payload: {
        data
    }
})

export const setModelesVehicule = ({data}) => ({
    type: SET_MODELES_VEHICULIER,
    payload: {
        data
    }
})


