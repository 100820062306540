import PropTypes from 'prop-types'
import SideBarContent from "../SideBarContent/SideBarContent";

const VehiculeSideBar = (props) => {
    const {
        logoMarque, situationVehicule, familleProduit, marque, cylindree, modele,
        isCurrentStep, slug, goTo
    } = props

    let modeleVehicule='';
    modeleVehicule += (marque !== undefined) ? marque : '';
    modeleVehicule += (cylindree !== undefined) ? ` ${cylindree}` : '';
    modeleVehicule += (modele !== undefined) ? ` ${modele}` : '';


    return (
        <SideBarContent
            title={'Votre véhicule'}
            icon={'moto'}
            isCurrentStep={isCurrentStep}
            slug={slug}
            goTo={goTo}
            tabContent={[
                {title: 'À propos de votre véhicule', 'value': situationVehicule},
                {title: 'Type de véhicule', 'value':  familleProduit},
                {title: 'Modèle de véhicule', 'value': modeleVehicule },
                { type: "img", value: logoMarque }
            ]}
        />
    );
};

VehiculeSideBar.propTypes = {
    slug: PropTypes.string,
    goTo: PropTypes.func
};

export default VehiculeSideBar;
