import VehiculeContainer from "./Steps/Vehicule/VehiculeContainer";
import ProfilContainer from "./Steps/Profil/ProfilContainer";
import AntecedentsContainer from "./Steps/Antecedents/AntecedentsContainer";
import InformationsVehiculeContainer from "./Steps/InformationsVehicule/InformationsVehiculeContainer";
import InformationsConducteurContainer from "./Steps/InformationsConducteur/InformationsConducteurContainer";
import TarifContainer from "./Steps/Tarif/TarifContainer";
import RecapitulatifContainer from "./Steps/Recapitulatif/RecapitulatifContainer";
import PaiementContainer from "./Steps/Paiement/PaiementContainer";
import EnvoiPiecesContainer from "./Steps/EnvoiPieces/EnvoiPiecesContainer";
import ValidationContainer from "./Steps/Validation/ValidationContainer";
import RecontactContainer from "./Steps/Recontact/RecontactContainer";
import BesoinsContainer from "./Steps/Besoins/BesoinsContainer";
import Signature from "./Steps/Signature/Signature";

let step = 0;

const RouteStep = [
    {
        step: step++,
        slug: 'votre-vehicule',
        title: 'Votre véhicule',
        path: '/votre-vehicule',
        main: (props) => <VehiculeContainer {...props} />
    },
    {
        step: step++,
        slug: 'vos-besoins',
        title: 'Vos besoins',
        path: '/vos-besoins',
        main: (props) => <BesoinsContainer {...props} />
    },
    {
        step: step++,
        slug: 'informations',
        title: 'Informations',
        path: '/informations-vehicule',
        main: (props) => <InformationsVehiculeContainer {...props} />
    },
    {
        step: step++,
        slug: 'votre-profil',
        title: 'Votre profil',
        path: '/votre-profil',
        main: (props) => <ProfilContainer {...props} />
    },
    {
        step: step++,
        slug: 'vos-antecedents',
        title: 'Vos antécédents',
        path: '/vos-antecedents',
        main: (props) => <AntecedentsContainer {...props} />
    },
    {
        step: step++,
        slug: 'votre-tarif',
        title: 'Votre tarif',
        path: '/votre-tarif',
        main: (props) => <TarifContainer {...props} />
    },
    {
        step: step++,
        slug: 'vos-infos',
        title: 'Vos infos',
        path: '/vos-infos',
        main: (props) => <InformationsConducteurContainer {...props} />
    },
    {
        step: step++,
        slug: 'recapitulatif',
        title: 'Récapitulatif',
        path: '/recapitulatif',
        main: (props) => <RecapitulatifContainer {...props} />
    },
    {
        step: step++,
        slug: 'paiement',
        title: 'Paiement',
        path: '/paiement',
        main: (props) => <PaiementContainer {...props} />
    },
    {
        step: step++,
        slug: 'validation',
        title: 'Validation',
        path: '/validation',
        main: (props) => <ValidationContainer {...props} />
    },
    {
        step: step++,
        slug: 'signature',
        title: 'Signature',
        path: '/signature',
        main: (props) => <Signature {...props} />
    },
    {
        step: step++,
        slug: 'envoi-des-pieces',
        title: 'Envoi des pièces',
        path: '/envoi-des-pieces',
        main: (props) => <EnvoiPiecesContainer {...props} />
    },
]

export default RouteStep
