import {setAnalytics} from '../../../actions/core/analytics/analytics.actions'
import _ from 'lodash'

export const analyticsMiddleware = ({dispatch}) => next => action => {
    next(action)

    if (action.meta && action.meta.analytics) {

        dispatch(setAnalytics({entity: action.meta.entity}))

        const { event, actionGoogle, libelle, categorie, valeur, unique, titleMatomo, valueMatomo, pageMatomo } = action.meta.analytics

        let found = unique || false

        if (unique){
            found = window.dataLayer.some((el) => {
                return el.libelle === libelle
            })
        }

        if (!found) {
            window.dataLayer.push({
                event,
                'action': actionGoogle,
                libelle,
                categorie,
                valeur
            })
            if (titleMatomo && valueMatomo){
                window._paq.push([
                    'trackEvent',
                    'infos',
                    titleMatomo,
                    valueMatomo
                ])
            }
            if (pageMatomo){
                window._paq.push(['setReferrerUrl', window.location.href]);
                let currentUrl = '/' + window.location.hash.substr(1);
                window._paq.push(['setCustomUrl', currentUrl]);
                window._paq.push(['setDocumentTitle', pageMatomo]);

                // remove all previously assigned custom variables, requires Matomo (formerly Piwik) 3.0.2
                window._paq.push(['deleteCustomVariables', 'page']);
                window._paq.push(['trackPageView']);

                // make Matomo aware of newly added content
                var content = document.getElementById('root');
                window._paq.push(['MediaAnalytics::scanForMedia', content]);
                window._paq.push(['FormAnalytics::scanForForms', content]);
                window._paq.push(['trackContentImpressionsWithinNode', content]);
                window._paq.push(['enableLinkTracking']);
            }
        }

        const datalayer = window.dataLayer

        const env = _.find(datalayer, 'environnement') ? _.find(datalayer, 'environnement') : {environnement: 'production'}

        if (!_.find(datalayer, 'environnement')){
            window.dataLayer.push(env)
        }
    }
}
