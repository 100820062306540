import {connect} from 'react-redux'
import Besoins from "./Besoins";
import formValueSelector from "redux-form/lib/formValueSelector";
import {bindActionCreators, compose} from "redux";
import {getTypeVehicule} from "../../../../../../redux/selectors/vehicule/vehicule.selectors";
import {clearFields} from "redux-form";
import {
    getApporteur1Init
} from "../../../../../../redux/selectors/init/init.selectors";

const mapStateToProps = state => {
    const selector = formValueSelector('devis')

    return {
        assistance: selector(state, 'DemandeTarif[DevoirConseil][DevoirASS2]'),
        montantAccessoires: String(selector(state, 'DemandeTarif[DevoirConseil][DevoirMontantACCESSEQP]')).replace(/\s/g, ''),
        montant: selector(state, 'DemandeTarif[DevoirConseil][DevoirMontantACCESSEQP]'),
        typeVehicule: getTypeVehicule(state),
        apporteur1: getApporteur1Init(state)
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    unsetValue: (field) => clearFields('devis', false, false, field),
}, dispatch)

const BesoinsContainer = compose(
    connect(mapStateToProps, mapDispatchToProps)
)(Besoins)

export default BesoinsContainer
