import { QuestionLabel } from '@amo/core/components/containers';
import { Autocomplete, DateField, Radio, Text, Select } from '@amo/core/components/forms';
import { getCountries } from '@amo/core/utils/requestsApi.js';
import { isValid, maxDateToday, min14Year, required } from '@amo/core/utils/validateField';
import _ from 'lodash';
import { useEffect } from 'react';
import { Field } from 'redux-form';

const TiersPayeur = (props) => {
    const { changeValue, countries, setCountries, tiersPayeurRelationship, tiersPayeurType } = props;

    useEffect(() => {
        if (_.isEmpty(countries)) {
            (async function fetchCountries() {
                let countries = await getCountries();
                setCountries({ countries });
            })();
        }

        if (!tiersPayeurType) {
            changeValue('DemandeContrat[TiersPayeur][Type]', 'P');
        }
    }, []);

    useEffect(() => {
        if (tiersPayeurType === 'M') {
            changeValue('DemandeContrat[TiersPayeur][BirthName]', '');
            changeValue('DemandeContrat[TiersPayeur][FirstName]', '');
            changeValue('DemandeContrat[TiersPayeur][BirthDate]', '');
            changeValue('DemandeContrat[TiersPayeur][BirthCountry]', '');
            changeValue('DemandeContrat[TiersPayeur][BirthCity]', '');
            changeValue('DemandeContrat[TiersPayeur][Relationship]', '');
            changeValue('DemandeContrat[TiersPayeur][ThirdPayerReason]', '');
        }
    }, [tiersPayeurType]);

    useEffect(() => {
        if (tiersPayeurRelationship && tiersPayeurRelationship !== 'A') {
            changeValue('DemandeContrat[TiersPayeur][ThirdPayerReason]', '');
        }
    }, [tiersPayeurRelationship]);

    return (
        <div>
            <hr />
            <QuestionLabel className={'mt-4'}>Tiers payeur</QuestionLabel>

            <div className={'bg-secondary-light border p-4 rounded text-left my-5 f-14'}>
                <p className={'d-inline'}>
                    La personne qui va payer les cotisations n'étant pas présente sur le contrat, nous avons besoin
                    d'avoir ses informations personnelles.
                </p>
            </div>

            <div className={'row'}>
                <div className={'col-lg-6 col-md-6 col-sm-6 col-6 my-2'}>
                    <Field
                        component={Radio}
                        type={'radio'}
                        name={`DemandeContrat[TiersPayeur][Type]`}
                        value={'P'}
                        label={'Personne physique'}
                        className={'small'}
                        validate={required}
                    />
                </div>
                <div className={'col-lg-6 col-md-6 col-sm-6 col-6 my-2'}>
                    <Field
                        component={Radio}
                        type={'radio'}
                        name={`DemandeContrat[TiersPayeur][Type]`}
                        value={'M'}
                        label={'Personne morale'}
                        className={'small'}
                        validate={required}
                    />
                </div>
            </div>

            <QuestionLabel className={'f-14 mt-4'} required>
                {tiersPayeurType === 'P' ? "Nom d'usage" : 'Nom de la société'}
            </QuestionLabel>
            <div className={'row'}>
                <div className={'col-12 col-md-6 mt-3 mt-md-6'}>
                    <Field
                        component={Text}
                        name={'DemandeContrat[TiersPayeur][LastName]'}
                        normalize={(v) => v.toUpperCase()}
                        validate={required}
                        maxLength={50}
                    />
                </div>
            </div>

            {tiersPayeurType === 'P' && (
                <>
                    <QuestionLabel className={'f-14 mt-4'} required>
                        Nom de naissance
                    </QuestionLabel>
                    <div className={'row'}>
                        <div className={'col-12 col-md-6 mt-3 mt-md-6'}>
                            <Field
                                component={Text}
                                name={'DemandeContrat[TiersPayeur][BirthName]'}
                                normalize={(v) => v.toUpperCase()}
                                validate={required}
                                maxLength={20}
                            />
                        </div>
                    </div>

                    <QuestionLabel className={'f-14 mt-4'} required>
                        Prénom
                    </QuestionLabel>
                    <div className={'row'}>
                        <div className={'col-12 col-md-6 mt-3 mt-md-6'}>
                            <Field
                                component={Text}
                                name={'DemandeContrat[TiersPayeur][FirstName]'}
                                normalize={(v) => v.toUpperCase()}
                                validate={required}
                                maxLength={20}
                            />
                        </div>
                    </div>

                    <QuestionLabel className={'f-14 mt-4'} required>
                        Date de naissance
                    </QuestionLabel>
                    <div className={'row'}>
                        <div className={'col-12 col-md-6 mt-3 mt-md-6'}>
                            <Field
                                component={DateField}
                                name={'DemandeContrat[TiersPayeur][BirthDate]'}
                                label={'JJ/MM/AAAA'}
                                validate={[isValid, required, maxDateToday, min14Year]}
                                maxDate={new Date()}
                            />
                        </div>
                    </div>

                    <QuestionLabel className={'f-14 mt-4'} required>
                        Pays de naissance
                    </QuestionLabel>
                    <div className={'row'}>
                        <div className={'col-12 col-md-6 mt-3 mt-md-6'}>
                            <Field
                                name={'DemandeContrat[TiersPayeur][BirthCountry]'}
                                component={Autocomplete}
                                data={countries.map((c) => ({ label: c.name, value: c.id }))}
                                required
                            />
                        </div>
                    </div>

                    <QuestionLabel className={'f-14 mt-4'} required>
                        Ville de naissance
                    </QuestionLabel>
                    <div className={'row'}>
                        <div className={'col-12 col-md-6 mt-3 mt-md-6'}>
                            <Field
                                name={'DemandeContrat[TiersPayeur][BirthCity]'}
                                component={Text}
                                validate={required}
                                maxLength={250}
                            />
                        </div>
                    </div>

                    <QuestionLabel className={'f-14 mt-4'} required>
                        Lien souscripteur / payeur
                    </QuestionLabel>
                    <div className={'row'}>
                        <div className={'col-12 col-md-6 mt-3 mt-md-6'}>
                            <Field
                                component={Select}
                                name={'DemandeContrat[TiersPayeur][Relationship]'}
                                validate={required}
                            >
                                <option value="">Sélectionnez...</option>
                                <option value="C">Conjoint</option>
                                <option value="E">Enfant</option>
                                <option value="FS">Frère/Sœur</option>
                                <option value="GP">Grand-parent</option>
                                <option value="P">Parent</option>
                                <option value="A">Autres</option>
                            </Field>
                        </div>
                    </div>

                    {tiersPayeurRelationship && tiersPayeurRelationship === 'A' && (
                        <>
                            <QuestionLabel className={'f-14 mt-4'} required>
                                Motif d'intervention du payeur
                            </QuestionLabel>
                            <div className={'row'}>
                                <div className={'col-12 col-md-6 mt-3 mt-md-6'}>
                                    <Field
                                        name={'DemandeContrat[TiersPayeur][ThirdPayerReason]'}
                                        component={Text}
                                        validate={required}
                                        maxLength={250}
                                    />
                                </div>
                            </div>
                        </>
                    )}
                </>
            )}
            <hr />
        </div>
    );
};

export default TiersPayeur;
