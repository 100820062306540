import './header.scss'

const Header = (props) => {
    const {apporteur1} = props

    return (
        <div className={`header header-${apporteur1.codeBelair}`}>
            <div className={'text-center m-auto position-relative container'}>
                <div className={'row'}>
                    &nbsp;
                </div>
            </div>
        </div>
    );
};

export default Header;
