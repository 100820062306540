import {connect} from 'react-redux';
import NavBar from "./NavBar";
import {getStep} from "../../../../../redux/selectors/ui/ui.selectors";
import { getRoutesByDevice } from '@amo/core/utils/getRoutesByDevice.js';
import {routesAffichees} from "../../../../../utils/function";
import RouteStep from "../RouteStep.jsx";

const mapStateToProps = state => {
    return {
        routes: routesAffichees(getRoutesByDevice(getStep(state),RouteStep)),
        activeStep: getStep(state),
    }
}

const NavBarContainer = connect(mapStateToProps)(NavBar)

export default NavBarContainer
