import {connect} from 'react-redux'
import {formValueSelector} from 'redux-form'
import _ from 'lodash'
import {QuestionContent} from "@amo/core/components/containers";
import {setIndexQuestion} from "../../../../../redux/actions/app/ui/ui.actions.js";
import {getIndexQuestion} from "../../../../../redux/selectors/ui/ui.selectors.js";

/*
 * recupere toute les question afficher, puis determine l'index de la question grace au e.dataset.name
 * si le status est enabled, que la question est complete et que le globalIndex est <= que l'index alors on set l'indexglobal à +1
 * */
const mapStateToProps = (state, ownProps) => {
    const selector = formValueSelector('devis')
    const { name } = ownProps

    const index = _.findIndex(document.getElementsByClassName('step'), e => {
        return e.dataset.name === name
    })

    return {
        index,
        globalIndex: getIndexQuestion(state),
        status: getIndexQuestion(state) >= index ? 'enabled' : 'disabled',
        isComplete: selector(state, name) !== undefined,
        value: selector(state, name)
    }
}

const mapDispatchToProps = {
    setIndexQuestion
}

const QuestionContentContainer = connect(mapStateToProps, mapDispatchToProps)(QuestionContent)

export default QuestionContentContainer
