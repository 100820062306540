import {connect} from 'react-redux'
import {compose} from 'redux'
import {change, formValueSelector, getFormValues} from "redux-form";
import Tarif from "./Tarif";
import {loader} from "@amo/core/components/loaders";
import { API_URL_WS_FORMULAIRE } from '@amo/core/constants';
import {TARIF} from "../../../../../../redux/actions/app/tarif/tarif.actions";
import {getErrorEntity, getLoadingEntity, getMaxStep, getStep} from "../../../../../../redux/selectors/ui/ui.selectors";
import {getToken} from "../../../../../../redux/selectors/init/init.selectors";
import {
    getCodeFormulePreconiseeTarif,
    getFormuleFranchises,
    getFormuleGarantiesACCESSEQP,
    getFormuleGarantiesASS,
    getFormuleGarantiesKSM,
    getFormuleGarantiesPCC,
    getFormuleGarantiesSelected,
    getFormuleGarantiesVALMAJ,
    getGarantiesInfoFranchiseDTA,
    getGarantiesInfoFranchiseVI,
    getInfoCodePromo,
    getListeFormulesTarif
} from "../../../../../../redux/selectors/tarif/tarif.selectors";
import ErreurTarifContainer from "./ErreurTarif/ErreurTarifContainer";
import {reformatGaranties} from "./reformatGaranties";
import {postFill} from "../../../../../../redux/actions/app/fill/fill.actions";
import {reformatFormules} from "./reformatFormules";
import {getTypeVehicule} from "../../../../../../redux/selectors/vehicule/vehicule.selectors";

const mapStateToProps = state => {
    const selector = formValueSelector('devis')
    const formuleChoisie = selector(state, 'DemandeTarif[Police][FormuleChoisie]')
    const fractionnement = selector(state, 'DemandeTarif[Police][FractionnementChoisi]')

    return {
        step: getStep(state),
        maxStep: getMaxStep(state),
        values: getFormValues('devis')(state),
        queryFill: `${API_URL_WS_FORMULAIRE}/fill/${getToken(state)}`,
        radioGroupSelectedASS: selector(state, 'options[ASS]'),
        radioGroupSelectedPCC: selector(state, 'options[PCC]'),
        radioGroupSelectedACCESSEQP: selector(state, 'optionsListe[ACCESSEQP]'),
        radioGroupSelectedKSM: selector(state, 'options[KSM]'),
        radioGroupSelectedVALMAJ: selector(state, 'options[VALMAJ]'),
        listeFormules: reformatFormules(getListeFormulesTarif(state), getTypeVehicule(state)),
        formulePreconisee: getCodeFormulePreconiseeTarif(state),
        formuleSelectionnee: formuleChoisie,
        fractionnement: fractionnement,
        query: `${API_URL_WS_FORMULAIRE}/get_tarif/${getToken(state)}`,
        loaded: getLoadingEntity(state, TARIF) === false,
        loading: getLoadingEntity(state, TARIF),
        infoDTA: getGarantiesInfoFranchiseDTA(state, formuleChoisie),
        infoVI: getGarantiesInfoFranchiseVI(state, formuleChoisie),
        garantiesSelected: getFormuleGarantiesSelected(state, formuleChoisie),
        garantiesASS: reformatGaranties(getFormuleGarantiesASS(state, formuleChoisie), fractionnement, getTypeVehicule(state)),
        garantiesPCC: reformatGaranties(getFormuleGarantiesPCC(state, formuleChoisie), fractionnement, getTypeVehicule(state)),
        garantiesKSM: reformatGaranties(getFormuleGarantiesKSM(state, formuleChoisie), fractionnement, getTypeVehicule(state)),
        garantiesVALMAJ: reformatGaranties(getFormuleGarantiesVALMAJ(state, formuleChoisie), fractionnement, getTypeVehicule(state)),
        garantiesACCESSEQP: reformatGaranties(getFormuleGarantiesACCESSEQP(state, formuleChoisie), fractionnement, getTypeVehicule(state)),
        garantiesVI: reformatGaranties(getFormuleGarantiesACCESSEQP(state, formuleChoisie), fractionnement, getTypeVehicule(state)),
        franchises: reformatGaranties(getFormuleFranchises(state, formuleChoisie), fractionnement, getTypeVehicule(state)),
        codePromo: getInfoCodePromo(state),
        codePromoInForm: selector(state, 'DemandeTarif[CodePromo][CodePromo]'),
        error: getErrorEntity(state, TARIF),
        nbMoisAssuranceMoto: selector(state, 'DemandeTarif[ListePersonnes][0][NbMoisAssuranceMoto]'),
        nbMoisAssuranceAuto: selector(state, 'DemandeTarif[ListePersonnes][0][NbMoisAssuranceAuto]'),
        valeurCrmMoto: selector(state, 'DemandeTarif[ListePersonnes][0][ValeurCrmMoto]'),
        valeurCrmAuto: selector(state, 'DemandeTarif[ListePersonnes][0][ValeurCrmAuto]'),
        selectedFranchiseVI: selector(state, 'optionsFranchise[RAFRAVI]'),
        selectedFranchiseDTA: selector(state, 'optionsFranchise[RAFRADTA]'),
    }
}


const mapDispatchToProps = {
    postFill,
    changeValue: (field, value) => change('devis', field, value)
}


const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { query, queryFill, step, maxStep, values } = stateProps
    const { postFill } = dispatchProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        reloadTarif: () => postFill({step, maxStep, form: values, query: queryFill, urlReloadTarif:query}),
    }
}


const TarifContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    loader({ErrorIndicator: ErreurTarifContainer, message: "Nous préparons nos formules !"})
)(Tarif)


export default TarifContainer
