import logo from '../../../../../assets/logo_horizontale.svg'
import mastercard_visa from '../../../../../assets/images/landing/mastercard-visa-logo.png'
import logo67256 from '../../../../../assets/images/landing/67256.png';
import logo100122 from '../../../../../assets/images/landing/100122.png';
import logo101853 from '../../../../../assets/images/landing/101853.png';
import logo393798 from '../../../../../assets/images/landing/393798.png';
import lbc from '../../../../../assets/images/landing/leboncoin.png'
import './topbar_landing.scss'
import {Icons} from "@amo/core/components/containers";
import {normalizeTel} from "@amo/core/utils/normalize"

const TopBarLanding = props => {
    const {formRappelAffiche, setFormRappelAffiche, apporteur2, utm_source, IDDevis} = props
    const logoApporteur2 = {
        "67256" : logo67256,
        "100122" : logo100122,
        "101853" : logo101853,
        "393798" : logo393798,
    };

    const telApporteur2 = {
        "67256" : '0178744585',
        "100122" : '0247515058',
        "101853" : '0178744582',
        "393798" : '0246990262',
    };
    const tel = apporteur2 ? telApporteur2[apporteur2] : utm_source === 'lbc' ? '0242070282' : '0178744581'

    return (
        <div className={'top-bar-landing bg-grey-blue py-4 text-primary-dark f-14'}>
            <div className={'container clearfix'}>
                <div className={'d-flex justify-content-between align-items-center'}>
                    <div className={'mr-3 logo'}>
                        <img src={logo} alt="Logo April Moto"/>
                    </div>
                    <div className={'d-flex flex-grow-1 justify-content-end justify-content-lg-between align-items-center'}>

                        <div className={'d-none d-lg-block'}>
                            {
                                apporteur2 || (utm_source && utm_source === "lbc") ?
                                    <div
                                        className={'infos-devis d-flex rounded bg-white align-items-center p-3 mr-3 justify-content-between'}>
                                        <div className={'pr-3'}>
                                            <p className={'m-0 font-weight-bold'}>Votre
                                                devis{!IDDevis ? ' avec :' : ''}</p>
                                            {
                                                IDDevis ? <p className={'m-0'}>N° {IDDevis} avec&nbsp;:</p> : ''
                                            }
                                        </div>


                                        <div className={'logo-landing'}>
                                            <img src={apporteur2 ? logoApporteur2[apporteur2] : lbc} alt={'logo'}
                                                 className={'w-100 h-100'}/>
                                        </div>

                                    </div> : ''
                            }
                        </div>

                        <div className={'row'}>
                            <div className={'col d-none d-xl-block'}>
                                <div className={'d-flex align-items-end f-14'}>
                                    <Icons className={'align-middle bg-white rounded-circle text-primary p-2 mr-3'} fontSize={22} icon={'locker'} />
                                    <div>
                                        <p className={'m-0'}>Paiement sécurisé</p>
                                        <img src={mastercard_visa} alt="logo Mastercard et logo Visa" height={15}/>
                                    </div>
                                </div>
                            </div>
                            <div className={'col d-none d-lg-block'}>
                                <a  href={`tel:${tel}`} className={'d-flex align-items-end f-14 numero'}>
                                    <Icons className={'align-middle bg-white rounded-circle text-primary p-2 mr-3'} fontSize={22} icon={'phone'} />
                                    <div>
                                        <p className={'m-0'}>Besoin d'aide&nbsp;?</p>
                                        <p className={'m-0 f-18 font-weight-bold text-primary'}>{normalizeTel(tel)}</p>
                                    </div>
                                </a>
                            </div>
                            <div className={'col d-lg-none numeroXs'}>
                                <a  href={`tel:${tel}}`} className={'btn btn-outline-primary medium-rounded btn-small'}>
                                    Besoin d'aide ?
                                </a>
                            </div>
                            <div className={'col d-none d-lg-flex'}>
                                <div className={'btn btn-outline-primary'} onClick={ () => { setFormRappelAffiche(!formRappelAffiche)} }>
                                    Etre rappelé
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TopBarLanding;
