import {API_ERROR, API_SUCCESS, apiRequest} from '../../../actions/core/api/api.actions'
import {setError, setLoader} from "../../../actions/app/ui/ui.actions";
import {setNotification} from "../../../actions/core/notifications/notifications.actions";
import {POST_DEJA_CLIENT} from "../../../actions/app/dejaClient/dejaClient.actions";
import {change} from "redux-form";
import _ from 'lodash'
import moment from 'moment'
import {normalizeTel} from "@amo/core/utils/normalize"

export const dejaClientMiddleware = () => next => action => {
    next(action)

    const { payload } = action

    switch (action.type){

        case POST_DEJA_CLIENT:

            const body = {
                'DemandeTarif': {
                    'ListePersonnes': [
                        {
                            'DejaClientSearch': payload.body.idDejaClient,
                            'TypePersonne': 'P',
                            'RoleConducteur': 'P'
                        }
                    ]
                }
            }

            next([
                apiRequest({body, method: 'POST', url: payload.data, entity: POST_DEJA_CLIENT}),
                setLoader({state: true, entity: POST_DEJA_CLIENT})
            ])

            break

        case `${POST_DEJA_CLIENT} ${API_SUCCESS}`:
            const dejaClient = action.payload.data.data.DemandeTarif.ListePersonnes[0]
            let nextAction = [
                setError({state: false, entity: POST_DEJA_CLIENT}),
                setLoader({state: false, entity: POST_DEJA_CLIENT})
            ]

            if(action.payload.data.data.DemandeTarif.ListePersonnes[0].DejaClient) {
                _.forEach(dejaClient, (value, key) => {
                    switch (key){
                        case 'ListePermis':
                            _.forEach(dejaClient[key], (v, k) => {
                                switch (v.TypePermis){
                                    case 'A':
                                        nextAction.unshift(change('devis', `DemandeTarif.ListePersonnes.0.ListePermis.3.DatePermis`, moment(v.DatePermis, "DD/MM/YYYY")))
                                        nextAction.unshift(change('devis', `DemandeTarif.ListePersonnes.0.ListePermis.3.TypePermis`, v.TypePermis))
                                        break;
                                    case 'B':
                                        nextAction.unshift(change('devis', `DemandeTarif.ListePersonnes.0.ListePermis.4.DatePermis`, moment(v.DatePermis, "DD/MM/YYYY")))
                                        nextAction.unshift(change('devis', `DemandeTarif.ListePersonnes.0.ListePermis.4.TypePermis`, v.TypePermis))
                                        break;
                                    case 'A1':
                                        nextAction.unshift(change('devis', `DemandeTarif.ListePersonnes.0.ListePermis.1.DatePermis`, moment(v.DatePermis, "DD/MM/YYYY")))
                                        nextAction.unshift(change('devis', `DemandeTarif.ListePersonnes.0.ListePermis.1.TypePermis`, v.TypePermis))
                                        break;
                                    case 'A2':
                                        nextAction.unshift(change('devis', `DemandeTarif.ListePersonnes.0.ListePermis.2.DatePermis`, moment(v.DatePermis, "DD/MM/YYYY")))
                                        nextAction.unshift(change('devis', `DemandeTarif.ListePersonnes.0.ListePermis.2.TypePermis`, v.TypePermis))
                                        break;
                                    case 'AM':
                                        nextAction.unshift(change('devis', `DemandeTarif.ListePersonnes.0.ListePermis.0.DatePermis`, moment(v.DatePermis, "DD/MM/YYYY")))
                                        nextAction.unshift(change('devis', `DemandeTarif.ListePersonnes.0.ListePermis.0.TypePermis`, v.TypePermis))
                                        break;
                                }
                            })
                            break;
                        case 'DejaClientAntecedents':
                            nextAction.unshift(change('devis', `DemandeTarif.ListePersonnes.0.${key}`, value))
                            break;
                        case 'TelFixe':
                        case 'TelPortable':
                            if (_.isEmpty(dejaClient.TelPortable) || dejaClient.TelPortable === undefined){
                                nextAction.unshift(change('devis', `DemandeTarif.ListePersonnes.0.TelPortable`, String(normalizeTel(dejaClient.TelFixe))))
                            } else {
                                nextAction.unshift(change('devis', `DemandeTarif.ListePersonnes.0.TelPortable`, String(normalizeTel(value))))
                            }
                            break;
                        case 'NbMoisAssuranceAuto':
                            nextAction.unshift(change('devis', `assure_auto`, value > 0 ? "1" : "0"))
                            nextAction.unshift(change('devis', `DemandeTarif.ListePersonnes.0.${key}`, String(value)))
                            break;
                        case 'NbMoisAssuranceMoto':
                            nextAction.unshift(change('devis', `assure_moto`, value > 0 ? "1" : "0"))
                            nextAction.unshift(change('devis', `DemandeTarif.ListePersonnes.0.${key}`, String(value)))
                            break;
                        case 'Ville':
                            nextAction.unshift(change('devis', `DemandeContrat[villeIBAN]`, value))
                            nextAction.unshift(change('devis', `DemandeTarif.ListePersonnes.0.${key}`, String(value)))
                            break;
                        case 'CP':
                            nextAction.unshift(change('devis', `DemandeContrat[cpIBAN]`, value))
                            nextAction.unshift(change('devis', `DemandeTarif.ListePersonnes.0.${key}`, String(value)))
                            break;
                        case 'BirthCity':
                            // ne récupère pas le BirthCity car par de BirthCP
                            break;
                        default:
                            nextAction.unshift(change('devis', `DemandeTarif.ListePersonnes.0.${key}`, key === 'DateNaissance' ? moment(value, 'DD/MM/YYYY') : typeof value === "number" && parseFloat(value) % 1 !== 0 ? value.toFixed(2) : String(value)))
                    }
                })
                nextAction.unshift(setNotification({entity: POST_DEJA_CLIENT, body: null, type: 'success', title: 'Vous êtes bien identifié !'}))

            } else {
                nextAction.unshift(change('devis', `DemandeTarif.ListePersonnes.0.NumeroClient`, ''))
                nextAction.unshift( setNotification({entity: POST_DEJA_CLIENT, body: `<p>Impossible de vous identifier</p>`, type: 'error', title: 'Erreur !', timeout: '10000'}))
            }

            next(nextAction)
            break

        case `${POST_DEJA_CLIENT} ${API_ERROR}`:
            next([
                setNotification({entity: POST_DEJA_CLIENT, body: `<p>Impossible de vous identifier</p>`, type: 'error', title: 'Erreur !', timeout: '10000'}),
                setError({state: true, entity: POST_DEJA_CLIENT}),
                setLoader({state: false, entity: POST_DEJA_CLIENT})
            ])
            break

        default:
            return null
    }
}