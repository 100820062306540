import services from "../../../../../../assets/images/landing/services.png";
import motards from "../../../../../../assets/images/landing/motards.png";
import indem from "../../../../../../assets/images/landing/indem.png";
import avance from "../../../../../../assets/images/landing/avance.png";
import label_excellence from "../../../../../../assets/images/landing/label_excellence.png";
import illustration from "../../../../../../assets/images/landing/femme-casque-moto-bulles.png";


const HeaderLandingDesktop = (props) => {
    const { marqueVehicule } = props

    return (
        <div className={'container f-14 '}>
            <div className={'illustration position-absolute'}>
                <img src={illustration} alt="" className={'w-100 h-100'}/>
            </div>

            <div className={'row position-relative'}>
                <img src={label_excellence} alt="" className={'position-absolute label_excellence'} width={110}/>
                <div className="col-9 offset-3 d-flex justify-content-between align-items-start bg-grey-blue py-5">
                    <div>
                        <div className="row font-weight-bold bloc-reassurance mr-3">
                            <div className="col-12 mb-5">
                                <div className="d-flex justify-content-left">
                                    <div><img src={services} alt="icone téléphone portable" className={'mr-4'} width={55}/></div>
                                    <p className={'m-0 d-inline-block'}>Un service client humain et&nbsp;disponible</p>
                                </div>
                            </div>
                            <div className="col-12 mb-5">
                                <div className="d-flex justify-content-left">
                                    <div><img src={motards} alt="icone casque moto" className={'mr-4'} width={45}/></div>
                                    <p className={'m-0 d-inline-block'}>Pour tous les motards et tous les&nbsp;deux-roues</p>
                                </div>
                            </div>
                            <div className="col-12 mb-5">
                                <div className="d-flex justify-content-left">
                                    <div><img src={indem} alt="icone parapluie" className={'mr-4'} width={45}/></div>
                                    <p className={'m-0 d-inline-block'}>Jusqu'à 1,2&nbsp;million d'euros&nbsp;indemnisés</p>
                                </div>
                            </div>
                            <div className="col-12 mb-5">
                                <div className="d-flex justify-content-left">
                                    <div><img src={avance} alt="icone main" className={'mr-4'} width={45}/></div>
                                    <p className={'m-0 d-inline-block'}>Pas d'avance d'argent en&nbsp;cas de&nbsp;sinistre</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={'bloc-assurance'}>
                        <p className={'f-21 mb-5'}>Vous êtes au meilleur endroit pour assurer votre {marqueVehicule ? <span className="text-orange">{marqueVehicule}</span> : 'véhicule'}...</p>

                        <p className={'m-0'}>Bonjour, bienvenue chez APRIL&nbsp;Moto.</p>
                        <p className={'mb-4'}>Comme vous, <span className={'font-weight-bold'}>500 000 motards nous ont déjà fait confiance.</span></p>

                        <p className={'font-weight-bold mb-5'}>Dans 3 clics, vous aurez <span className={'text-orange'}>votre attestation d'assurance</span> !</p>

                        <span className={'btn-start'}>
                            <span className={'btn btn-primary medium-rounded btn-sm'} onClick={() => document.getElementById('formulaire').scrollIntoView({behavior: 'smooth'})} data-id="btn-start">C'est parti !</span>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
};


export default HeaderLandingDesktop;