import {combineReducers} from 'redux'
import {reducer as form} from 'redux-form'
import {uiReducer} from "../reducers/ui/ui.reducer"
import {initReducer} from "../reducers/init/init.reducer";
import {demandeTarifReducer} from "../reducers/demandeTarif/demandeTarif.reducer";
import {tarifReducer} from "../reducers/tarif/tarif.reducer";
import {vehiculeReducer} from "../reducers/vehicule/vehicule.reducer";

const AppReducer = combineReducers({
    form,
    ui: uiReducer,
    init: initReducer,
    demandeTarif: demandeTarifReducer,
    tarif: tarifReducer,
    vehicule: vehiculeReducer
})

export default AppReducer
