import axios from 'axios'
import * as Sentry from "@sentry/react";
import {API_REQUEST, apiError, apiSuccess} from '../../../actions/core/api/api.actions'
import {setMaintenance} from '../../../actions/app/ui/ui.actions'
import {clearLocalStorage} from "../../../actions/core/localStorage/localStorage.actions";
import {APP_NAME} from "@amo/core/constants";
import moment from "moment";

export const apiMiddleware = ({dispatch}) => next => action => {
    next(action)

    if (action.type.includes(API_REQUEST)){
        const { meta: { url, method, entity, otherData }, data } = action.payload

        axios({method, url, data})
            .then (response => {
                dispatch(apiSuccess({response: response.data, entity, otherData}))

                /* Refresh timer token */
                const storage = JSON.parse(localStorage.getItem(APP_NAME))
                const expire = moment().toJSON()

                localStorage.setItem(APP_NAME, JSON.stringify({...storage, expire}))
            })
            .catch(error => {
                if (error.response){
                    switch (error.response.status){

                        case 401:
                            dispatch(apiError({error, entity, otherData}))
                            break

                        case 503:
                            dispatch(apiError({error, entity, otherData}))
                            dispatch(setMaintenance({state: true, entity}))
                            break

                        case 498:
                            dispatch(apiError({error, entity, otherData}))
                            dispatch(clearLocalStorage({entity: 'API'}))
                            window.location = window.location.pathname
                            break

                        default:
                            dispatch(apiError({error, entity, otherData}))
                    }
                    Sentry.captureException(error);
                    Sentry.captureMessage(`${error.response.data?.code} ${error.response.data?.source || 'formulaire-tlv'} - ${error.response.data?.error}`);
                } else {
                    dispatch(apiError({error, entity, otherData})) // OPTION REQUEST
                }
            })
    }
}
