import toastr from 'toastr'
import {SET_NOTIFICATION} from '../../../actions/core/notifications/notifications.actions'

export const notificationsMiddleware = () => next => action => {
    next(action)

    if (action.type.includes(SET_NOTIFICATION)) {

        const { type, body, timeout, title, onHidden } = action.meta

        toastr.options = {
            'closeButton': true,
            'debug': false,
            'newestOnTop': true,
            'progressBar': true,
            "positionClass": "toast-top-center",
            'preventDuplicates': true,
            'onclick': null,
            'showDuration': '300',
            'hideDuration': '1000',
            'timeOut': timeout || (type === 'success' ? '10000' : '0'),
            'extendedTimeOut': '1000',
            'showEasing': 'swing',
            'hideEasing': 'linear',
            'showMethod': 'fadeIn',
            'hideMethod': 'fadeOut',
            "tapToDismiss": true,
            "onHidden": onHidden
        }

        toastr[type](body, title)
    }
}
