import _ from 'lodash';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { useNavigate } from 'react-router-dom';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import LibertyRider from '../../../../assets/images/liberty-rider.png';
import FooterContainer from './Footer/FooterContainer';
import Header from './Header/Header';
import HeaderError from './Header/HeaderError';
import HeaderLandingContainer from './HeaderLanding/HeaderLandingContainer';
import HeaderTarifContainer from './HeaderTarif/HeaderTarifContainer';
import './initformulaire.scss';
import NavBarContainer from './NavBar/NavBarContainer';
import SideBarContainer from './SideBar/SideBarContainer';
import TopBarLanding from './TopBarLanding/TopBarLanding';
import TopBarContainer from "./TopBar/TopBarContainer.js";
import {ChampsObligatoires} from "@amo/core/components/containers";

const InitFormulaire = (props) => {
    const {
        changeValue,
        url_pay,
        stepPaiement,
        stepRecapitulatif,
        step,
        routes,
        stepTarif,
        tarifLoaded,
        utm_source,
        apporteur2,
        IDDevis,
        stepRecontact,
        apporteur1,
        realisateur,
    } = props;
    const navigate = useNavigate();

    const [etape, setEtape] = useState(null);
    const [showPopupDevis, setShowPopupDevis] = useState(false);
    const app2Landings = ['67256', '100122', '101853', '393798'];
    const [showLanding, setShowLanding] = useState(
        (apporteur2 && _.includes(app2Landings, apporteur2.codeBelair)) ||
            (utm_source && (utm_source === 'adwords' || utm_source === 'lbc')),
    );

    useEffect(() => {
        if (step !== etape) {
            setEtape(step);
            navigate(`${_.find(routes, ['step', step]).path}${window.location.search}`);
        }
        setShowPopupDevis(false);
    }, [step]);

    useEffect(() => {
        if (step === stepRecapitulatif || step === stepPaiement) {
            changeValue('token', url_pay.token);
            changeValue('url_return', url_pay.url_return);
        }

        setShowLanding(
            (apporteur2 && _.includes(app2Landings, apporteur2.codeBelair)) ||
                (utm_source && (utm_source === 'adwords' || utm_source === 'lbc')),
        );
    }, []);

    if (!realisateur) {
        return (
            <>
                <TopBarContainer />
                <HeaderError />
            </>
        );
    }

    return (
        <div className={'position-relative'} style={{ minHeight: '100vh' }}>
            {showLanding ? (
                <TopBarLanding
                    apporteur2={apporteur2 ? apporteur2.codeBelair : null}
                    utm_source={utm_source}
                    IDDevis={IDDevis}
                />
            ) : (
                <TopBarContainer>
                    {!isMobileOnly && step !== stepRecontact && <NavBarContainer />}
                    <div className={'col-3'}>&nbsp;</div>
                </TopBarContainer>
            )}

            {step === 0 ? (
                <>
                    {showLanding ? (
                        <HeaderLandingContainer
                            IDDevis={IDDevis}
                            apporteur2={apporteur2 ? apporteur2.codeBelair : null}
                            utm_source={utm_source}
                        />
                    ) : (
                        <Header apporteur1={apporteur1} />
                    )}
                </>
            ) : (
                ''
            )}

            <div id="formulaire" />

            {step === stepTarif && tarifLoaded && <HeaderTarifContainer />}

            <div className="container steps-formulaire">
                {step === stepTarif && (
                    <div className={'text-center'}>
                        <img src={LibertyRider} alt="Liberty Rider" />
                    </div>
                )}

                <div className={'row'}>
                    <div className={'col-12 col-lg-4 order-lg-1'}>
                        <SideBarContainer />
                    </div>

                    <SwitchTransition>
                        <CSSTransition
                            timeout={500}
                            key={etape}
                            addEndListener={(node, done) => node.addEventListener('transitionend', done, false)}
                            classNames={`fade-left`}
                        >
                            <div className={'col-12 order-lg-0 col-lg-8'}>
                                {routes.map((r, i) => {
                                    if (window.location.pathname === r.path) {
                                        return <div key={i}>{r.main(props)}</div>;
                                    }
                                })}
                                <ChampsObligatoires />
                            </div>
                        </CSSTransition>
                    </SwitchTransition>
                </div>
            </div>

            <FooterContainer />
        </div>
    );
};

InitFormulaire.defaultProps = {
    step: 0,
};

InitFormulaire.propTypes = {
    step: PropTypes.number,
    route: PropTypes.array,
};

export default InitFormulaire;
