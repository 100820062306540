import {Icons} from "@amo/core/components/containers";

const CancelPaiement = (props) => {
    const { marque, modele, immatriculation, formule, valid, handleSubmit, postPaySubmit, payLoaded, previousStep } = props

    return (
        <div>
            <div className={'bg-secondary-light p-4 mb-5 text-center rounded'}>
                <div className={'f-21 text-primary-light mb-3'}>
                    <Icons className={`d-inline-block position-relative mr-2`} fontSize={30} icon={'triangle'}/>
                    Votre souscription est en attente de paiement.
                </div>

                <div className={'f-21 mb-3'}>
                    Vous êtes à deux pas de finaliser la souscription<br/>pour votre <b>{marque} {modele}</b> immatriculé <b>{immatriculation}</b> avec la formule <b>{formule}</b>&nbsp;!
                </div>

                <div className={'f-18'}>
                    Un espace de paiement sécurisé plus rapide et sécurisée&nbsp;!
                </div>
            </div>

            <div className={'submit-row mt-5'}>
                <button
                    className={`${!valid && 'disabled'} btn btn-primary medium-rounded float-right mb-2 order-lg-1`}
                    data-id="btn-souscription"
                    onClick={valid ? handleSubmit(postPaySubmit) : null}>
                    {!!payLoaded
                        ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
                        : 'Je finalise la souscription immédiatement'
                    }
                </button>

                <button className={`btn btn-outline-primary medium-rounded order-lg-0`} onClick={() => previousStep()}>Retour</button>
            </div>
        </div>
    );
};

export default CancelPaiement;
