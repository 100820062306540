import { QuestionLabel } from '@amo/core/components/containers';
import {Radio, Text} from '@amo/core/components/forms';
import { required } from '@amo/core/utils/validateField';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';

const PersonneFields = (props) => {
    const { personneIndex, data } = props;

    return (
        <>
            <QuestionLabel className={'f-14 mt-4'} required>Civilité&nbsp;</QuestionLabel>
            <div className={'row'}>
                <div className={'col-lg-4 col-md-6 col-sm-6 col-6 my-2'}>
                    <Field
                        component={Radio}
                        type={'radio'}
                        name={`DemandeTarif[ListePersonnes][${personneIndex}][Civilite]`}
                        value={'1'}
                        label={'Madame'}
                        className={'small'}
                    />
                </div>
                <div className={'col-lg-4 col-md-6 col-sm-6 col-6 my-2'}>
                    <Field
                        component={Radio}
                        type={'radio'}
                        name={`DemandeTarif[ListePersonnes][${personneIndex}][Civilite]`}
                        value={'0'}
                        label={'Monsieur'}
                        className={'small'}
                    />
                </div>
            </div>

            <QuestionLabel className={'f-14 mt-4'} required>Nom d'usage&nbsp;</QuestionLabel>
            <div className={'row'}>
                <div className={'col-12 col-md-6 mt-2 mt-md-2'}>
                    <Field
                        component={Text}
                        name={`DemandeTarif[ListePersonnes][${personneIndex}][Nom]`}
                        normalize={(v) => v.toUpperCase()}
                        validate={required}
                    />
                </div>
            </div>

            <QuestionLabel className={'f-14 mt-4'} required>Nom de naissance&nbsp;</QuestionLabel>
            <div className={'row'}>
                <div className={'col-12 col-md-6 mt-2 mt-md-2'}>
                    <Field
                        component={Text}
                        name={`DemandeTarif[ListePersonnes][${personneIndex}][BirthName]`}
                        normalize={(v) => v.toUpperCase()}
                        validate={required}
                    />
                </div>
            </div>

            <QuestionLabel className={'f-14 mt-4'} required>Prénom&nbsp;</QuestionLabel>
            <div className={'row'}>
                <div className={'col-12 col-md-6 mt-2 mt-md-2'}>
                    <Field
                        component={Text}
                        name={`DemandeTarif[ListePersonnes][${personneIndex}][Prenom]`}
                        normalize={(v) => v.toUpperCase()}
                        validate={required}
                    />
                </div>
            </div>

            {
                (
                    data?.DemandeTarif?.ListePersonnes[personneIndex]?.Prenom &&
                    (
                        data?.DemandeTarif?.ListePersonnes[personneIndex]?.Nom === data?.DemandeTarif?.ListePersonnes[personneIndex]?.Prenom ||
                        data?.DemandeTarif?.ListePersonnes[personneIndex]?.BirthName === data?.DemandeTarif?.ListePersonnes[personneIndex]?.Prenom
                    )
                ) &&
                <div className={'row'}>
                    <div className={'col-12 col-md-6 mt-0'}>
                        <div className="bg-warning p-4 rounded text-center f-14 mt-0" >
                            <p className={'mb-0'}>Votre nom et prénom sont identiques.<br/>Merci de vérifier vos
                                informations avant de continuer.</p>
                        </div>
                    </div>
                </div>
            }
        </>
    );
};

PersonneFields.propTypes = {
    personneIndex: PropTypes.number.isRequired,
};

export default PersonneFields;
