import _ from 'lodash'
import { SimpleTitle, Icons } from '@amo/core/components/containers';

const EnvoiPieces = (props) => {
    const { documentsUrl, listeDocuments } = props

    const setTrackingGoToDocuments = () => {
        window.dataLayer.push({
            event: 'click',
            'action': 'clickButton',
            libelle: 'Envoyer vos documents',
            categorie: 'GO-TO-DOCUMENTS'
        })
        window._paq.push([
            'trackEvent',
            'infos',
            'clickButton',
            'Envoyer vos documents'
        ])
    }

    return (
        <div>
            <div>
                <div className={'bg-secondary-light p-4 mb-5 text-center rounded'}>
                    <div className={'f-21 text-primary-light mb-3'}>
                        <Icons className={`d-inline-block position-relative mr-2`} fontSize={30} icon={'triangle'}/>
                        Il vous reste des documents à nous faire parvenir.
                    </div>

                    <div className={'f-21 mb-3'}>
                        Vous pouvez nous envoyer vos documents via la plateforme d'envoi des documents APRIL Moto&nbsp;!
                    </div>

                    <div className={'f-18'}>
                        Une plateforme rapide et sécurisée&nbsp;!
                    </div>
                </div>
            </div>

            <div className="row">
                <SimpleTitle className={'mt-5'}>Vos documents à nous transmettre</SimpleTitle>

                <ul className={'mt-4'}>
                    {
                        _.map(listeDocuments, (d, i) => (
                            <li key={i} className={'p-1'}>{d.Description}</li>
                        ))
                    }
                </ul>
            </div>

            <div className={'row mt-5'}>
                <div className="col text-center">
                    <a href={documentsUrl} className={'btn btn-primary medium-rounded text-uppercase mb-2'} onClick={() => setTrackingGoToDocuments()}>Envoyer vos documents</a>
                </div>
            </div>
        </div>
    );
};

export default EnvoiPieces;
