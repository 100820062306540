const DevisSideBar = () => {
    return (
        <div className={'bg-warning p-4 rounded text-left mb-2 f-14'}>
            <p className={'mb-0'}><strong>Attention !</strong> Devis Orange
            </p>
        </div>
    );
};

export default DevisSideBar;
